import { BoxProps } from "@chakra-ui/react";
import React from "react";

import SummaryBanner from "./SummaryBanner";

type SummaryGenericTemplateBannerProps = {
  bannerKey: string;
} & BoxProps;

const SummaryGenericTemplateBanner: React.FC<
  SummaryGenericTemplateBannerProps
> = ({ bannerKey, ...boxProps }) => {
  return (
    <SummaryBanner
      bannerKey={bannerKey}
      bannerTypeForDismissing="SummaryGenericTemplateBanner"
      {...boxProps}
    >
      Position-specific topics are not included, as a job description was not
      found.
    </SummaryBanner>
  );
};

export default SummaryGenericTemplateBanner;
