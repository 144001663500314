import { Center } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { Alert, LoadingIndicator } from "../../../components";
import EditTemplate from "../../components/Candidate/summary/EditTemplate";
import { useCandidateSummaryTemplateQuery } from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useUrlState } from "../home/useTabUrlState";

const CandidateSummaryEditPage: React.FC = () => {
  const { templateId } = useParams() as {
    templateId: string;
  };
  const [fromCandidateId] = useUrlState({ key: "candidateId" });
  const [fromPositionId] = useUrlState({ key: "positionId" });
  const [fromExtension] = useUrlState({ key: "fromExtension" });

  const { loading, error, data } = useCandidateSummaryTemplateQuery({
    variables: { templateId, positionId: fromPositionId },
  });
  const template = data?.candidateSummaryTemplate;

  return (
    <DefaultLayout>
      {loading && (
        <Center>
          <LoadingIndicator />
        </Center>
      )}
      {!loading && error && (
        <Alert status="error" description={error.message} />
      )}
      {!loading && !error && template && (
        <EditTemplate
          template={template}
          fromPositionId={fromPositionId}
          fromCandidateId={fromCandidateId}
          fromExtension={fromExtension === "true"}
        />
      )}
    </DefaultLayout>
  );
};
export default CandidateSummaryEditPage;
