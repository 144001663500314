import React from "react";

import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import NotFound from "../../pages/not-found/NotFoundPage";

type FeatureGateProps = {
  // Add active feature flags here as enum values to avoid typos
  flag:
    | "ai-recruiter:v0"
    | "analytics:quality-report"
    | "analytics:my-insights"
    | "analytics:topic-trends"
    | "analytics:candidate-motivations"
    | "analytics:candidate-questions"
    | "analytics:skills-report"
    | "custom_templates_v2:manage";
  children: JSX.Element;
};

const FeatureGate: React.FC<FeatureGateProps> = ({ flag, children }) => {
  const enabled = useFeatureFlagForCurrentOrImpersonatedUser(flag);
  if (!enabled) {
    return <NotFound />;
  }
  return children;
};

export default FeatureGate;
