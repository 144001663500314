import { Box } from "@chakra-ui/react";
import React from "react";
import { SortingRule } from "react-table";

import { SortableTable, SortableTableColumn } from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { PageOptions } from "../../../utils/types";
import { CallAiSummaryTemplateListItemFragment } from "../../graphql";
import CallAiSummaryTemplateActions from "./CallAiSummaryTemplateActions";

type ListTemplate = CallAiSummaryTemplateListItemFragment;

interface CallAiSummaryTemplateListProps {
  templates: ListTemplate[];
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
}

const CallAiSummaryTemplateList: React.FC<CallAiSummaryTemplateListProps> = ({
  templates,
  pageOptions,
  loading,
  sortBy,
}) => {
  const columns: Array<SortableTableColumn<ListTemplate>> = [
    {
      Header: "Template Name", // TODO: resolve whether title case
      accessor: "name",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => <>{template.name || "Untitled"}</>,
    },
    {
      Header: "Author",
      accessor: "creator",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => <Box whiteSpace="nowrap">{template.creator.fullName}</Box>,
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => (
        <Box whiteSpace="nowrap">{formatRelativeDate(template.createdAt)}</Box>
      ),
    },
    {
      Header: "Visibility",
      accessor: "visibility",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => (
        <Box whiteSpace="nowrap">
          {template.visibility === "PUBLIC" ? "Org-wide" : "Private"}
        </Box>
      ),
    },
    {
      Header: " ", // Empty header for the menu (must be a space)
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => <CallAiSummaryTemplateActions template={template} />,
    },
  ];

  return (
    <Box mb="8">
      <SortableTable
        columns={columns}
        data={templates}
        manualPagination
        pageOptions={pageOptions}
        width="100%"
        initialSort={sortBy}
        loading={loading}
      />
    </Box>
  );
};

export default CallAiSummaryTemplateList;
