import { useState } from "react";

import { daysAgo } from "../../../../../utils/datetime";
import {
  FilterValue,
  SkillReportSkillsSource,
  SkillsReportWithResultsFragment,
} from "../../../../graphql";
import { PositionOptions } from "./SkillsReportPositionSelect";
import { DateRangeState, SkillsBuilderSkill } from "./types";

export type CandidateFilterType = "interviewStage" | "minInterviews" | "any";

export interface UseSkillsReportBuilderReturn {
  selectedPositions: PositionOptions;
  setSelectedPositions: React.Dispatch<React.SetStateAction<PositionOptions>>;
  candidateFilter: CandidateFilterType | undefined;
  setCandidateFilter: React.Dispatch<
    React.SetStateAction<CandidateFilterType | undefined>
  >;
  interviewStages: FilterValue[];
  setInterviewStages: React.Dispatch<React.SetStateAction<FilterValue[]>>;
  minInterviewCount: number;
  setMinInterviewCount: React.Dispatch<React.SetStateAction<number>>;
  dateRange: DateRangeState;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeState>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  skills: SkillsBuilderSkill[];
  setSkills: React.Dispatch<React.SetStateAction<SkillsBuilderSkill[]>>;
  source: SkillReportSkillsSource;
  setSource: React.Dispatch<React.SetStateAction<SkillReportSkillsSource>>;
}

const default180Days: DateRangeState = {
  start: daysAgo(180),
  end: new Date(),
};

const guessCandidateFilter = (
  minimumInterviewCountFilter: number,
  parsedStages: FilterValue[]
): CandidateFilterType => {
  if (minimumInterviewCountFilter && minimumInterviewCountFilter > 1) {
    return "minInterviews";
  }
  if (parsedStages && parsedStages.length > 0) {
    return "interviewStage";
  }
  return "any";
};

const useSkillsReportBuilder = (
  report?: SkillsReportWithResultsFragment
): UseSkillsReportBuilderReturn => {
  const minInterviewCountFilter = report?.minimumInterviewCountFilter || 1;
  let parsedStages: FilterValue[] = [];
  try {
    parsedStages = JSON.parse(report?.interviewStageFilters || "[]");
  } catch (error) {
    parsedStages = [];
  }
  const defaultDateRange = report
    ? {
        start: new Date(report?.dateRangeStart),
        end: new Date(report?.dateRangeEnd),
      }
    : default180Days;
  const reportSkills = report?.skills ?? [];

  const [selectedPositions, setSelectedPositions] = useState<PositionOptions>(
    report?.positions || []
  );
  const [candidateFilter, setCandidateFilter] = useState<
    CandidateFilterType | undefined
  >(guessCandidateFilter(minInterviewCountFilter, parsedStages));
  const [interviewStages, setInterviewStages] = useState<FilterValue[]>(
    parsedStages || []
  );
  const [minInterviewCount, setMinInterviewCount] = useState<number>(
    minInterviewCountFilter
  );
  const [dateRange, setDateRange] = useState<DateRangeState>(defaultDateRange);
  const [title, setTitle] = useState<string>(report?.title || "");
  const [skills, setSkills] = useState<SkillsBuilderSkill[]>(reportSkills);
  const [source, setSource] = useState<SkillReportSkillsSource>(
    report?.source || SkillReportSkillsSource.User
  );

  return {
    selectedPositions,
    setSelectedPositions,
    candidateFilter,
    setCandidateFilter,
    interviewStages,
    setInterviewStages,
    minInterviewCount,
    setMinInterviewCount,
    dateRange,
    setDateRange,
    title,
    setTitle,
    skills,
    setSkills,
    source,
    setSource,
  };
};

export default useSkillsReportBuilder;
