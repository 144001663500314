import { BoxProps } from "@chakra-ui/react";
import React from "react";

import SummaryBanner from "./SummaryBanner";

type SummaryCallCountBannerProps = {
  bannerKey: string;
  numSummaryCalls?: number;
  numCurrentVisibleCalls?: number;
} & BoxProps;

const SummaryCallCountBanner: React.FC<SummaryCallCountBannerProps> = ({
  bannerKey,
  numSummaryCalls,
  numCurrentVisibleCalls,
  ...boxProps
}) => {
  // we are not backfilling; so the last summary might not have a calls list
  // in that case, just don't show the banner
  if (numSummaryCalls === 0) {
    return null;
  }
  if (numSummaryCalls === undefined || numCurrentVisibleCalls === undefined) {
    return null;
  }
  if (numSummaryCalls === numCurrentVisibleCalls) {
    return null;
  }

  return (
    <SummaryBanner
      bannerKey={bannerKey}
      bannerTypeForDismissing="SummaryCallCountBanner"
      {...boxProps}
    >
      {`Summary and topic coverage generated using ${numSummaryCalls} candidate call${
        numSummaryCalls === 1 ? "" : "s"
      }. Private and restricted calls cannot be used for summaries.`}
    </SummaryBanner>
  );
};

export default SummaryCallCountBanner;
