import { Flex, Icon, Text, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

const AskHeader = (): JSX.Element => (
  <Flex alignItems="center">
    <Text fontWeight="600" color="gray.800" mr={1} ml={1}>
      Ask assistant
    </Text>
    <ChakraTooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      placement="top"
      label={
        <>
          Assistant responses are generated using interviews YOU have access to.
          <br />
          <br />
          The AI-powered assistant is unable to provide subjective judgments. As
          with any AI-generated content, always check for accuracy. Use the
          interview recordings to verify any statements made.
        </>
      }
    >
      <Icon
        boxSize={4}
        color="gray.500"
        as={HiOutlineInformationCircle}
        mr={2}
        display="span"
        mb="1px"
      />
    </ChakraTooltip>
  </Flex>
);

export default AskHeader;
