import { Box, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

type SectionProps = {
  title: string;
  prompt: string;
  onUpdate?(title: string, prompt: string): void;
};

const Section: React.FC<SectionProps> = ({ title, prompt, onUpdate }) => {
  const [stateTitle, setTitle] = useState<string>(title);
  const [statePrompt, setPrompt] = useState<string>(prompt);

  useEffect(() => {
    onUpdate?.(stateTitle, statePrompt);
  }, [stateTitle, statePrompt]);

  return (
    <Box width="100%" pt="1.5">
      <FormControl mb="3" width="100%" borderColor="gray.200">
        <FormLabel>Section</FormLabel>
        <Input
          placeholder="E.g. Candidate Background"
          width="100%"
          value={stateTitle}
          onChange={(e) => setTitle(e.target.value)}
          autoFocus
        />
      </FormControl>
      <FormControl width="100%" borderColor="gray.200">
        <FormLabel>Description</FormLabel>
        <Textarea
          width="100%"
          placeholder="E.g. What is the candidate's educational background? Professional work experience? What other important skills and experience do they have that is relevant to this position?"
          minHeight="106px"
          value={statePrompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};

export default Section;
