import { Box, Flex, Icon, Switch, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiArrowDownTray } from "react-icons/hi2";

import { Button, Link, LoadingIndicator } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { AnalyticsDimension } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import AnalyticsDateSelect from "./AnalyticsDateSelect";
import AnalyticsFilters from "./AnalyticsFilters";
import AnalyticsHeroContent from "./AnalyticsHeroContent";
import AnalyticsInfoAlert from "./AnalyticsInfoAlert";
import AnalyticsInfoModal from "./AnalyticsInfoModal";
import MetricChart from "./chart/MetricChart";
import { ATS_METRIC_NAMES } from "./const";
import EditGenderLabelsButton from "./EditGenderLabelsButton";
import LabeledChartSelect from "./LabeledChartSelect";
import { MetricConfig } from "./MetricConfig";
import MetricSummary from "./MetricSummary";
import ToggleFiltersButton from "./ToggleFiltersButton";
import ToggleFiltersPanel from "./ToggleFiltersPanel";
import { AnalyticsConfig, PivotedDataPoint } from "./types";
import { AnalyticsData } from "./useAnalyticsData";
import { GenderLabels } from "./useGenderLabels";
import { mapFiltersToAlgoliaURL } from "./utils";

export type AnalyticsMetricsContentProps = {
  analyticsConfig: AnalyticsConfig;
  analyticsData: AnalyticsData;
  genderLabels: GenderLabels;
  flaggedFeatures: {
    genderSegmentation: boolean;
    statusSegmentation: boolean;
    togglePerformanceOverlay: boolean;
    drilldowns: boolean;
  };
};

const AnalyticsMetricsContent: React.FC<AnalyticsMetricsContentProps> = ({
  analyticsConfig,
  analyticsData,
  genderLabels,
  flaggedFeatures,
}) => {
  const sendGAEvent = useSendGAEvent();
  const csvEnabled = useFeatureFlag("analytics:csv-download");
  const [showFilters, setShowFilters] = useState(true);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    { defaultHeight: 50 }
  );
  const { metric } = analyticsConfig;
  const metricConfig = MetricConfig[metric];
  const [performanceOverlay, setPerformanceOverlay] = useState(false);
  const onDrillDown = (entry: PivotedDataPoint): void => {
    const url = mapFiltersToAlgoliaURL(analyticsConfig, entry, metric);
    window.open(url, "_blank");
    sendGAEvent("metric_drilldown", "analytics", metric);
  };

  // Set showFilters to true when query param source=alert and some filters are present
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    const departments = urlParams.get("departments");
    const positions = urlParams.get("positions");
    const interviewers = urlParams.get("interviewers");
    const stages = urlParams.get("stages");
    if (
      source === "alert" &&
      (departments || positions || interviewers || stages)
    ) {
      setShowFilters(true);
    }
  }, []);

  const segmentSingleSelect = analyticsConfig.secondaryDimension;
  const hideSegment =
    segmentSingleSelect.options.length === 0 ||
    (segmentSingleSelect.options.length === 1 &&
      segmentSingleSelect.options[0].value === AnalyticsDimension.None);

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            {metricConfig.displayName}
          </Text>
          <AnalyticsInfoModal metric={metric} />
        </Flex>

        <Flex alignItems="center">
          {csvEnabled && !analyticsData.heroType && (
            <Button
              aria-label="Download CSV results"
              fontSize="sm"
              leftIcon={<Icon as={HiArrowDownTray} boxSize="5" />}
              isLoading={analyticsData.csvLoading}
              disabled={analyticsData.csvLoading || analyticsData.loading}
              variant="ghost"
              onClick={() => {
                sendGAEvent("export_metric_data_csv", "analytics");
                analyticsData.downloadCSV();
              }}
              onFocus={(e) => e.preventDefault()}
              hidden={
                !analyticsData.loading && !analyticsData?.dataPoints?.length
              }
            >
              Download CSV
            </Button>
          )}
          {analyticsData.filtersAvailable && (
            <Flex ml="6">
              <ToggleFiltersButton
                data-testid="analytics--filters-button"
                open={showFilters}
                toggleFilters={() => {
                  sendGAEvent(
                    `filters_${!showFilters ? "open" : "closed"}`,
                    "analytics"
                  );
                  setShowFilters((state) => !state);
                }}
              />
              <AnalyticsDateSelect
                state={analyticsConfig.dateRange.value}
                onSelect={analyticsConfig.dateRange.setValue}
              />
            </Flex>
          )}
        </Flex>
      </Flex>

      {ATS_METRIC_NAMES.includes(metric) &&
        analyticsConfig.dateRange.value.start < new Date("2022-03-07") && (
          <AnalyticsInfoAlert status="warning" mt="4">
            Data is unavailable for this report before March 7th, 2022.
          </AnalyticsInfoAlert>
        )}

      {/* Dimensions */}
      {analyticsData.filtersAvailable && (
        <ToggleFiltersPanel
          showFilters={showFilters}
          filterHeights={filterHeights}
        >
          <AnalyticsFilters
            queryConfig={analyticsConfig}
            queryVariables={analyticsData.commonQueryVariables}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
          />
        </ToggleFiltersPanel>
      )}

      {analyticsData.heroType ? (
        <AnalyticsHeroContent
          analyticsHero={analyticsData.heroType}
          metric={metric}
        />
      ) : (
        <>
          <Box background="gray.100" minHeight="1px" maxHeight="1px" mt="0" />
          <MetricSummary
            metric={metric}
            data={analyticsData.dataPoints}
            loading={analyticsData.loading}
            primaryDimension={analyticsConfig.primaryDimension.value}
            dateRange={analyticsConfig.dateRange.value}
          />
          <Box background="gray.100" minHeight="1px" maxHeight="1px" mb="6" />

          {/* Chart Options */}
          <Flex
            data-tour-id="analytics-dimensions"
            justifyContent="space-between"
          >
            <Flex>
              <LabeledChartSelect
                testid="analytics--group-menu"
                mr="4"
                label="Group"
                flexDir="column"
                alignItems="flex-start"
                singleSelect={analyticsConfig.primaryDimension}
              />
              {!hideSegment &&
                (flaggedFeatures.genderSegmentation ||
                  flaggedFeatures.statusSegmentation) && (
                  <LabeledChartSelect
                    data-testid="analytics--segment-menu"
                    minW="145px"
                    flexDir="column"
                    alignItems="stretch"
                    mr="4"
                    label="Segment"
                    singleSelect={analyticsConfig.secondaryDimension}
                  />
                )}
              {flaggedFeatures.togglePerformanceOverlay && (
                <Flex
                  mr="4"
                  alignItems="flex-start"
                  height="40px"
                  flexDir="column"
                >
                  <Text fontSize="sm" color="gray.600" mr="2">
                    Performance
                  </Text>
                  <Switch
                    size="md"
                    mt="3.5"
                    defaultChecked={performanceOverlay}
                    onChange={(e) => setPerformanceOverlay(e.target.checked)}
                  />
                </Flex>
              )}
            </Flex>
            <Flex>
              <LabeledChartSelect
                data-testid="analytics--sort-menu"
                minW="110px"
                flexDir="column"
                mr="4"
                alignItems="flex-start"
                label="Sort"
                singleSelect={analyticsConfig.chartSort}
              />
              {analyticsConfig.primaryDimension.value !==
                AnalyticsDimension.Performance && (
                <LabeledChartSelect
                  data-testid="analytics--show-menu"
                  minW="150px"
                  flexDir="column"
                  alignItems="flex-start"
                  label="Show"
                  singleSelect={analyticsConfig.chartLimit}
                />
              )}
            </Flex>
          </Flex>
          {analyticsConfig.secondaryDimension.value ===
            AnalyticsDimension.Gender &&
            !genderLabels.loading && (
              <Flex mt="6" mb="6">
                <AnalyticsInfoAlert status="info" flex="1">
                  BrightHire predicts the gender of candidates based upon
                  candidate name. Predictions are for directional guidance and
                  shown in aggregate only.{" "}
                  <Link
                    href="https://help.brighthire.ai/en/articles/6925822-brighthire-candidate-gender-data"
                    target="_blank"
                  >
                    Learn more
                  </Link>
                  {genderLabels.canUpdateGenderLabels && (
                    <>
                      {" "}
                      or{" "}
                      <EditGenderLabelsButton
                        genderLabels={genderLabels.genderLabels}
                      />
                      .
                    </>
                  )}
                </AnalyticsInfoAlert>
              </Flex>
            )}

          {/* Chart */}
          <Box mt="5">
            {analyticsData.loading && <LoadingIndicator />}
            {!analyticsData.loading && !genderLabels.loading && (
              <MetricChart
                data={analyticsData.dataPoints || []}
                benchmarkRange={analyticsData.benchmarkRange}
                limit={analyticsConfig.chartLimit.value}
                sort={analyticsConfig.chartSort.value}
                metric={metric}
                primaryDimension={analyticsConfig.primaryDimension}
                secondaryDimension={analyticsConfig.secondaryDimension.value}
                performanceOverlay={performanceOverlay}
                drillDownEnabled={flaggedFeatures.drilldowns}
                onDrillDown={onDrillDown}
                genderLabels={genderLabels.genderLabels}
                {...analyticsConfig.filters}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default AnalyticsMetricsContent;
