import {
  Box,
  Checkbox,
  ListItem,
  Select,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  errorToast,
  FormControl,
  Link,
  successToast,
  useToast,
} from "../../../components";
import { CurrentUserFragment } from "../../graphql";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import SettingsNote from "./shared/SettingsNote";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface UserScorecardSettingsProps {
  currentUser: CurrentUserFragment;
}

type FormValues = {
  autofillGreenhouseScorecard: boolean;
  draftScorecardNoteFormat: string;
};

export const UserScorecardSettings: React.FC<UserScorecardSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const [updateCurrentUser, { loading }] = useUpdateCurrentUser({
    onCompleted: () => {
      successToast(toast, "Settings saved");
    },
    onError: () => {
      errorToast(toast, "There was a problem updating your settings");
    },
  });

  const { control, register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      autofillGreenhouseScorecard: currentUser.autofillGreenhouseScorecard,
      draftScorecardNoteFormat: currentUser.draftScorecardNoteFormat,
    },
  });

  const options = [
    { value: "QANDA", label: "AI Interview Notes" },
    { value: "SCORECARD", label: "Scorecard Notes Template" },
  ];

  const forcedOn =
    currentUser.organization.requiresGreenhouseScorecardAutoDraft;
  return (
    <SettingsPageContainer
      heading="Scorecard settings"
      subHeading="Choose how to manage your scorecards with BrightHire"
    >
      <form
        onSubmit={handleSubmit((formData) => {
          updateCurrentUser({ variables: formData });
        })}
      >
        <Tooltip
          placement="bottom-start"
          ml="4"
          mt="-2"
          label={
            forcedOn
              ? "Your administrator has required this to be enabled for all BrightHire users"
              : undefined
          }
        >
          <FormControl
            isDisabled={forcedOn}
            helpText={
              <Box fontSize="sm" lineHeight="5">
                This will enable BrightHire to automatically draft your
                Greenhouse scorecard with evidence from your AI-generated
                interview notes. You&apos;ll always have the opportunity to
                review and edit your feedback before you submit.
              </Box>
            }
          >
            <Checkbox
              {...register("autofillGreenhouseScorecard")}
              defaultChecked={currentUser.autofillGreenhouseScorecard}
            >
              Automatically draft my scorecards
            </Checkbox>
          </FormControl>
        </Tooltip>
        <SettingsNote mt="6" hideIcon>
          <UnorderedList ml="5" mb="2">
            <ListItem>
              BrightHire will only create a draft if the scorecard is empty.
              This ensures that your feedback is never overwritten.
            </ListItem>
            <ListItem>
              If you&apos;ve already started filling out your scorecard, you can
              still use the BrightHire{" "}
              <Link
                href="https://chromewebstore.google.com/detail/brighthire/mbokiighkhdopgedihndpibkincpcgan"
                target="_blank"
              >
                browser extension
              </Link>{" "}
              to import your notes.
            </ListItem>
            <ListItem>
              The interview must be scheduled in Greenhouse and have a scorecard
              link attached to it for auto drafting to work.
            </ListItem>
          </UnorderedList>
          <Link
            href="https://help.brighthire.ai/en/articles/10000464-greenhouse-scorecard-auto-draft"
            target="_blank"
          >
            Learn more
          </Link>
        </SettingsNote>
        <Text mb={2} mt={4}>
          Draft Scorecard Note Format
        </Text>
        <FormControl
          helpText={
            <Box fontSize="sm" lineHeight="5">
              Choose how your interview notes will be formatted when
              automatically drafting scorecards. AI Interview Notes provides a
              Q&A format, while Scorecard Notes Template aligns with your
              scorecard structure.
            </Box>
          }
        >
          <Controller
            {...register("draftScorecardNoteFormat")}
            name="draftScorecardNoteFormat"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select note format..."
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                aria-label="Select scorecard note format"
              >
                {options.map((opt) => (
                  <option value={opt.value} key={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <Button type="submit" mt="12" isLoading={loading}>
          Save
        </Button>
      </form>
    </SettingsPageContainer>
  );
};
