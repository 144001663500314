import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import ColumnHeaderV2 from "./ColumnHeaderV2";
import { SubTopic, Topic } from "./types";
import { encodeToAnchor } from "./utils";

type TopicCoverageProps = {
  topics: Topic[];
  onNavigate: () => void;
};

const TopicCoverageListV2: React.FC<TopicCoverageProps> = ({
  topics,
  onNavigate,
}) => {
  // Reduce motion on first render so they don't animate open, but animated after that
  const [enableMotion, setEnabledMotion] = React.useState(false);
  useEffect(() => setEnabledMotion(true));
  const [expandedIndices, setExpandedIndices] = React.useState<number[]>(
    Array.from({ length: topics.length }, (_, i) => i)
  );

  return (
    <Box overflow="auto" width="418px" flexShrink="0" px={6}>
      <Flex flexDir="row" mb={2} ml={5}>
        <ColumnHeaderV2>Topic</ColumnHeaderV2>
        <Box ml="auto">
          <ColumnHeaderV2>Coverage</ColumnHeaderV2>
        </Box>
      </Flex>
      <Accordion
        allowMultiple
        index={expandedIndices}
        onChange={(indices: number[]) => setExpandedIndices(indices)}
        reduceMotion={!enableMotion}
      >
        {topics.map((topic) => {
          return (
            <TopicCoverage
              key={topic.name}
              topic={topic}
              onNavigate={onNavigate}
            />
          );
        })}
      </Accordion>
    </Box>
  );
};

type TopicNoteCount = {
  topic: Topic;
  onNavigate: () => void;
};

const TopicCoverage: React.FC<TopicNoteCount> = ({ topic, onNavigate }) => {
  const { name } = topic;
  let maxSubtopicCitations = 0;
  topic.subTopics.forEach((subTopic) => {
    let subtopicTotalCitations = 0;
    subTopic.notes.forEach((note) => {
      subtopicTotalCitations += note.citations.length;
    });
    maxSubtopicCitations = Math.max(
      maxSubtopicCitations,
      subtopicTotalCitations
    );
  });
  return (
    <AccordionItem border="none">
      {({ isExpanded }: { isExpanded: boolean }) => (
        <>
          <AccordionHeader
            title={name}
            isExpanded={isExpanded}
            numCitations={maxSubtopicCitations}
            onNavigate={onNavigate}
          />
          <AccordionPanel py={0} px={0}>
            {topic.subTopics
              .filter((subTopic) => subTopic?.name.trim())
              .map((subTopic) => (
                <SubTopicCoverage
                  key={subTopic.name}
                  subtopic={subTopic}
                  onNavigate={onNavigate}
                />
              ))}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

const AccordionHeader: React.FC<{
  title: string;
  isExpanded: boolean;
  numCitations: number;
  onNavigate: () => void;
}> = ({ title, isExpanded, numCitations, onNavigate }) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Text role="heading" fontSize="md" color="gray.800" pb="1">
      <AccordionButton
        _hover={{ bg: "transparent", color: "blue.600" }}
        borderRadius="base"
        py="0"
        height="10"
        px={0}
        onClick={() => {
          sendGAEvent(
            isExpanded
              ? "candidate_summary_topic_collapse"
              : "candidate_summary_topic_expand",
            "candidate"
          );
        }}
      >
        <AccordionIcon
          transform={isExpanded ? "rotate(0deg)" : "rotate(-90deg)"}
        />
        <Flex
          flex="1"
          alignItems="center"
          justifyContent="flex-start"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Text
            fontSize="sm"
            textAlign="left"
            textOverflow="ellipsis"
            overflow="hidden"
            marginRight="auto"
            color="gray.900"
            fontWeight="medium"
            onClick={() => {
              sendGAEvent("candidate_summary_topic_click", "candidate");
              onNavigate();
            }}
            _hover={{
              color: "blue.600",
            }}
          >
            <a href={`#${encodeToAnchor("topic", title)}`}>{title}</a>
          </Text>
          <Box ml="2">
            <CoverageBadge coverage={citationsToCoverage(numCitations)} />
          </Box>
        </Flex>
      </AccordionButton>
    </Text>
  );
};

type Coverage = "low" | "medium" | "high" | "none";

const CoverageBadgeInner: React.FC<{
  color: string;
  bg: string;
  label: string;
  coverage: Coverage;
}> = ({ color, bg, label, coverage }) => {
  const tooltipLabel = {
    low: "There are 1-2 citations for this topic",
    medium: "There are 3-5 citations for this topic",
    high: "There are 6+ citations for this topic",
    none: "There are no citations for this topic",
  }[coverage];
  return (
    <Tooltip label={tooltipLabel}>
      <Box
        display="inline-block"
        color={color}
        bg={bg}
        borderRadius="3px"
        py="2px"
        fontSize="xs"
        textTransform="uppercase"
        width="46px"
        textAlign="center"
      >
        {label}
      </Box>
    </Tooltip>
  );
};

export const CoverageBadge: React.FC<{ coverage: Coverage }> = ({
  coverage,
}) => {
  if (coverage === "low") {
    return (
      <CoverageBadgeInner
        color="blue.500"
        bg="blue.50"
        label="Low"
        coverage={coverage}
      />
    );
  }
  if (coverage === "medium") {
    return (
      <CoverageBadgeInner
        color="white"
        bg="blue.300"
        label="Med"
        coverage={coverage}
      />
    );
  }
  if (coverage === "high") {
    return (
      <CoverageBadgeInner
        color="white"
        bg="blue.500"
        label="High"
        coverage={coverage}
      />
    );
  }
  return (
    <CoverageBadgeInner
      color="yellow.700"
      bg="yellow.100"
      label="None"
      coverage={coverage}
    />
  );
};

export const citationsToCoverage = (citations: number): Coverage => {
  if (citations === 0) {
    return "none";
  }
  if (citations <= 2) {
    return "low";
  }
  if (citations <= 5) {
    return "medium";
  }
  return "high";
};

type SubTopicCoverage = {
  subtopic: SubTopic;
  onNavigate: () => void;
};

const SubTopicCoverage: React.FC<SubTopicCoverage> = ({
  subtopic,
  onNavigate,
}) => {
  const sendGAEvent = useSendGAEvent();
  let citations = 0;
  subtopic.notes.forEach((note) => {
    citations += note.citations.length;
  });
  return (
    <Box borderLeftWidth="1px" borderColor="gray.100" pl={4} ml="9px">
      <Flex py={2} flexDir="row" alignItems="flex-start" gap="16px">
        <Text
          fontSize="sm"
          overflow="hidden"
          marginRight="auto"
          color="gray.900"
          flexShrink="1"
          _hover={{
            color: "blue.600",
          }}
        >
          <a
            href={`#${encodeToAnchor("subtopic", subtopic.name)}`}
            onClick={() => {
              sendGAEvent("candidate_summary_subtopic_click", "candidate");
              onNavigate();
            }}
          >
            {subtopic.name}
          </a>
        </Text>
        <Box ml="2">
          <CoverageBadge coverage={citationsToCoverage(citations)} />
        </Box>
      </Flex>
    </Box>
  );
};

export default TopicCoverageListV2;
