import {
  Box,
  Center,
  Flex,
  Icon,
  Text,
  Tooltip,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HiOutlinePencil, HiOutlinePlus, HiOutlineX } from "react-icons/hi";
import { HiArrowDownTray } from "react-icons/hi2";
import { Navigate } from "react-router-dom";
import Select, { components, OptionProps } from "react-select";

import {
  Button,
  ComputerIcon,
  IconButton,
  useToast,
} from "../../../../components";
import AILoading from "../../../../components/LoadingIndicator/AILoading";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  useCreateCustomTopicMutation,
  useCustomTopicCountsLazyQuery,
  useCustomTopicOccurrencesCsvLazyQuery,
  useCustomTopicOccurrencesLazyQuery,
  useDeleteCustomTopicMutation,
  useEditCustomTopicMutation,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import AnalyticsFilters from "../AnalyticsFilters";
import AnalyticsInfoModal from "../AnalyticsInfoModal";
import {
  ReportContainer,
  ResultControls,
  ResultHeader,
} from "../AnalyticsReport";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import LabeledChartSelect from "../LabeledChartSelect";
import { CommonQueryVariables } from "../types";
import { useAnalyticsSelectTheme } from "../useAnalyticsSelectTheme";
import AnalyticsTopicTrendsChart from "./AnalyticsTopicTrendsChart";
import AnalyticsTopicTrendsTable from "./AnalyticsTopicTrendsTable";
import CustomTopicForm from "./CustomTopicForm";
import { DefaultTopic, TopicOption } from "./types";
import useTopicTrendsConfig from "./useTopicTrendsConfig";
import useTopicTrendsOptions from "./useTopicTrendsOptions";

const CustomOption = (
  props: OptionProps<TopicOption, false>
): React.ReactElement => {
  if (props.data.value === "new_topic") {
    return (
      <components.Option {...props}>
        <Flex alignItems="center">
          <Icon as={HiOutlinePlus} w="14px" h="14px" />
          <Box ml="2">
            <Text>New topic</Text>
          </Box>
        </Flex>
      </components.Option>
    );
  }
  return <components.Option {...props} />;
};

const AnalyticsTopicTrends: React.FC = () => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();

  const queryConfig = useTopicTrendsConfig();
  const {
    loading: customTopicsLoading,
    options: allTopicOptions,
    groups: topicGroups,
    refetchCustomTopics,
    currentOption,
    previousOption,
  } = useTopicTrendsOptions(queryConfig.topic.value);

  const [previewTopic, setPreviewTopic] = useState<TopicOption | undefined>();
  const [showTopicForm, setShowTopicForm] = useBoolean(
    queryConfig.topic?.value === "new_topic"
  );
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const queryVariables: CommonQueryVariables = useMemo(() => {
    return {
      dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
      dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
      positions: queryConfig.filters.positions,
      interviewers: queryConfig.filters.interviewers,
      departments: queryConfig.filters.departments,
      stages: queryConfig.filters.stages,
    };
  }, [
    formatISODate(queryConfig.dateRange.value.start),
    formatISODate(queryConfig.dateRange.value.end),
    queryConfig.filters.positions.join(","),
    queryConfig.filters.interviewers.join(","),
    queryConfig.filters.departments.join(","),
    queryConfig.filters.stages.join(","),
  ]);

  const [createCustomTopic] = useCreateCustomTopicMutation({
    onCompleted: (response) => {
      const topicId = response?.createCustomTopic?.topic?.id;
      if (topicId) {
        toast({
          title: "Topic created",
          status: "success",
        });
        refetchCustomTopics();
        queryConfig.topic.setValue(topicId);
        setPreviewTopic(undefined);
        setShowTopicForm.off();
      }
    },
  });
  const [editCustomTopic] = useEditCustomTopicMutation({
    onCompleted: () => {
      toast({
        title: "Topic saved",
        status: "success",
      });
      refetchCustomTopics();
      setPreviewTopic(undefined);
      setShowTopicForm.off();
    },
  });
  const [deleteCustomTopic] = useDeleteCustomTopicMutation({
    onCompleted: (response) => {
      if (response?.deleteCustomTopic?.success) {
        refetchCustomTopics();
        toast({
          title: "Topic deleted",
          status: "success",
        });
        if (previousOption) {
          queryConfig.topic.setValue(previousOption.value);
        } else {
          queryConfig.topic.setValue(DefaultTopic.Ai);
        }
        setPreviewTopic(undefined);
        setShowTopicForm.off();
      }
    },
  });

  const [getTopicTableData, { data: topicTableData, loading: tableLoading }] =
    useCustomTopicOccurrencesLazyQuery({ fetchPolicy: "cache-and-network" });
  const [getTopicChartData, { data: topicChartData, loading: chartLoading }] =
    useCustomTopicCountsLazyQuery({ fetchPolicy: "cache-and-network" });

  // The table data gets its own useEffect since it alone relies on the speakerType filter
  useEffect(() => {
    if (!currentOption || currentOption.value === "new_topic" || previewTopic)
      return;
    getTopicTableData({
      variables: {
        ...queryVariables,
        speakerType: queryConfig.speakerType.value,
        keywords: currentOption.keywords,
        matchRule: currentOption.matchRule,
      },
    });
  }, [
    currentOption?.value,
    currentOption?.keywords.join(),
    currentOption?.matchRule,
    queryVariables,
    queryConfig.speakerType.value,
    previewTopic,
  ]);

  useEffect(() => {
    if (!currentOption || currentOption.value === "new_topic" || previewTopic)
      return;
    getTopicChartData({
      variables: {
        ...queryVariables,
        speakerType: queryConfig.speakerType.value,
        keywords: currentOption.keywords,
        matchRule: currentOption.matchRule,
      },
    });
  }, [
    currentOption?.value,
    currentOption?.keywords.join(),
    currentOption?.matchRule,
    queryVariables,
    queryConfig.speakerType.value,
    previewTopic,
  ]);

  const tableData = topicTableData?.customTopicOccurrences;
  const chartData = topicChartData?.customTopicCounts;

  const sampleSize = chartData?.data.reduce((acc: number, d) => {
    const total = d.totalCalls ?? 0;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return acc + total;
  }, 0);

  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 340,
      ...containerStyles,
    }),
    ...selectStylesRest,
  };

  const [fetchCsv, { loading: csvLoading }] =
    useCustomTopicOccurrencesCsvLazyQuery({
      fetchPolicy: "network-only",
    });

  const downloadCSV = useCallback(() => {
    if (!currentOption || currentOption.value === "new_topic") return;
    fetchCsv({
      variables: {
        ...queryVariables,
        topicName: currentOption.label,
        keywords: currentOption.keywords,
        matchRule: currentOption.matchRule,
        speakerType: queryConfig.speakerType.value,
      },
      onCompleted(data) {
        const url = data?.customTopicOccurrencesCsv?.url;
        if (url) {
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });
  }, [currentOption?.value, queryVariables, queryConfig.speakerType.value]);

  // when user closes the form we clear the preview topic
  // if there's an unsaved new topic, we reset to an existing topic
  const clearForm = useCallback(() => {
    setPreviewTopic(undefined);
    if (currentOption?.value === "new_topic") {
      if (previousOption) {
        queryConfig.topic.setValue(previousOption?.value);
      } else {
        queryConfig.topic.setValue(DefaultTopic.Ai);
      }
    }
  }, [previousOption, queryConfig.topic, setPreviewTopic]);

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  if (customTopicsLoading) return null;

  const canEditTopic = currentOption?.type === "custom";
  const topicName = previewTopic
    ? previewTopic.label
    : currentOption?.label || "";
  const topicKeywords = previewTopic
    ? previewTopic.keywords
    : currentOption?.keywords || [];
  const showBlankState =
    (!currentOption || queryConfig.topic.value === "new_topic") &&
    !previewTopic;
  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
        py="4"
        px="8"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Topic Trends
          </Text>
          <AnalyticsInfoModal dangerousHTMLString="The Topic Trends report tracks how frequently specific topics or keywords appear in interview conversations over time. The trend chart shows the percentage of interviews where topics were discussed, while the examples section displays specific mentions of the selected topic. Custom topics can be created by combining keywords and matching rules." />
        </Flex>
        <Flex ml="auto">
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
          </Flex>
        </Flex>
      </Flex>
      <Box px="8" borderTop="1px" borderBottom="1px" borderColor="gray.200">
        <Flex flex="1" flexDir="row" alignItems="center" py="5">
          <Text fontSize="sm" color="gray.600" mr="2">
            Filters
          </Text>
          <Flex flex="1">
            <AnalyticsFilters
              queryConfig={queryConfig}
              queryVariables={queryVariables}
            />
          </Flex>
        </Flex>
      </Box>
      <Flex px="8" mt="8" mb="6" flexDir="column">
        <Text fontSize="3xl" fontWeight={400}>
          How often are common interview topics being discussed?
        </Text>
        <Text fontWeight={400} color="gray.400">
          Identify the frequency of discussion for topics specified by
          BrightHire and/or you across all candidates and positions for the
          defined date range.
        </Text>
        <Flex flexWrap="wrap" gap={6} mt="6">
          <Flex flex="1" minW="500px" alignItems="center">
            <Text fontSize="sm" color="gray.600" fontWeight={400} mr="2">
              Topic
            </Text>
            <Box maxWidth="400px">
              <Select
                styles={commonSelectStyles}
                theme={selectTheme}
                options={topicGroups}
                value={allTopicOptions.find(
                  (o) => o.value === queryConfig.topic.value
                )}
                onChange={(option) => {
                  if (!option?.value) return;
                  if (option.value === queryConfig.topic.value) return;
                  queryConfig.topic.setValue(option.value);
                  setPreviewTopic(undefined);
                  if (option?.value === "new_topic") {
                    setShowTopicForm.on();
                  } else {
                    setShowTopicForm.off();
                  }
                }}
                components={{ Option: CustomOption }}
              />
            </Box>
            <Tooltip
              label={
                showTopicForm
                  ? "Cancel"
                  : canEditTopic
                  ? "Edit topic"
                  : "BrightHire topics cannot be edited"
              }
              placement="bottom-start"
            >
              <IconButton
                color="gray.900"
                aria-label="Edit topic"
                variant="icon"
                icon={
                  showTopicForm ? (
                    <HiOutlineX strokeWidth="1.5" size="20px" />
                  ) : (
                    <HiOutlinePencil strokeWidth="1.5" size="20px" />
                  )
                }
                disabled={!showTopicForm && !canEditTopic}
                ml={2}
                onClick={() => {
                  if (showTopicForm) {
                    clearForm();
                    setShowTopicForm.off();
                  } else {
                    setShowTopicForm.on();
                  }
                }}
              />
            </Tooltip>
            {!showTopicForm && (
              <Button
                colorScheme="blue"
                aria-label="Create custom topic"
                variant="ghost"
                leftIcon={<HiOutlinePlus width="14px" height="14px" />}
                ml={2}
                onClick={() => {
                  setPreviewTopic(undefined);
                  queryConfig.topic.setValue("new_topic");
                  setShowTopicForm.on();
                }}
              >
                Custom topic
              </Button>
            )}
          </Flex>
          <LabeledChartSelect
            data-testid="analytics-topic-trends--speaker-menu"
            flexDir="row"
            alignItems="center"
            label="Include mentions from"
            singleSelect={queryConfig.speakerType}
            customSelectStyles={{
              container: (provided: any) => ({
                ...provided,
                width: "250px",
              }),
            }}
          />
        </Flex>
      </Flex>
      {showTopicForm && (
        <Box px="8">
          <CustomTopicForm
            key={currentOption?.value}
            topic={currentOption}
            reportLoading={tableLoading || chartLoading}
            onCancel={() => {
              clearForm();
              setShowTopicForm.off();
            }}
            onDelete={(topicId) => {
              deleteCustomTopic({
                variables: {
                  topicId,
                },
              });
            }}
            onSaveTopic={(topicOption) => {
              const variables = {
                name: topicOption.label,
                keywords: topicOption.keywords,
                matchRule: topicOption.matchRule,
              };
              if (topicOption.value) {
                editCustomTopic({
                  variables: {
                    topicId: topicOption.value,
                    ...variables,
                  },
                });
              } else {
                createCustomTopic({
                  variables: {
                    ...variables,
                  },
                });
              }
            }}
            onGenerateResults={(topicOption) => {
              const variables = {
                ...queryVariables,
                keywords: topicOption.keywords,
                matchRule: topicOption.matchRule,
                speakerType: queryConfig.speakerType.value,
              };
              getTopicTableData({
                variables: {
                  ...variables,
                },
              });
              getTopicChartData({
                variables: {
                  ...variables,
                },
              });
              setPreviewTopic(topicOption);
            }}
          />
        </Box>
      )}
      {showBlankState && (
        <Box px="8">
          <Center
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="8px"
            mt="2"
          >
            <VStack spacing={5} py="12" px="6">
              <ComputerIcon />
              <Text color="gray.600" fontWeight="600">
                Select or create a topic above to generate a report.
              </Text>
            </VStack>
          </Center>
        </Box>
      )}
      {!showBlankState && (
        <Box px="8">
          <ReportContainer>
            <ResultHeader
              headerText={`Trends for topic: '${topicName}'`}
              captionText={`The percentage of interviews in which the topic was discussed. ${
                sampleSize ? `Sample: ${sampleSize} interviews.` : ""
              }`}
            >
              <Text
                color="gray.600"
                fontWeight="400"
                fontSize="sm"
                whiteSpace="nowrap"
              >
                {queryConfig.dateRange.displayValue}
              </Text>
            </ResultHeader>
            {chartLoading && (
              <Center>
                <AILoading />
              </Center>
            )}
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {!!chartData?.data?.length && !chartLoading && (
                <AnalyticsTopicTrendsChart
                  data={chartData?.data}
                  queryConfig={queryConfig}
                  bucketInterval={chartData?.bucketInterval}
                  bucketSize={chartData?.bucketSize}
                />
              )}
            </Flex>
          </ReportContainer>
          <ReportContainer mt="6" mb="6">
            <ResultHeader headerText={`Topic mentions: '${topicName}'`}>
              <Text
                color="gray.600"
                fontWeight="400"
                fontSize="sm"
                whiteSpace="nowrap"
              >
                {queryConfig.dateRange.displayValue}
              </Text>
            </ResultHeader>
            <ResultControls justifyContent="flex-end">
              <Tooltip label="Download full CSV results">
                <IconButton
                  aria-label="Download full CSV results"
                  icon={<Icon as={HiArrowDownTray} boxSize="5" />}
                  isLoading={csvLoading || tableLoading}
                  variant="ghost"
                  onClick={() => {
                    sendGAEvent("download_topic_trend_results", "analytics");
                    downloadCSV();
                  }}
                  onFocus={(e) => e.preventDefault()}
                  hidden={!tableLoading && !tableData?.data.length}
                />
              </Tooltip>
            </ResultControls>

            <Box mt="3" width="100%">
              {tableLoading && <AnalyticsReportTableSkeleton />}
              {!tableLoading && (
                <AnalyticsTopicTrendsTable
                  tableData={tableData?.data || []}
                  topicName={topicName}
                  topicKeywords={topicKeywords}
                />
              )}
            </Box>
          </ReportContainer>
        </Box>
      )}
    </>
  );
};

export default AnalyticsTopicTrends;
