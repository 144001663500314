import {
  FormControl,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import config from "../../config";
import { Button } from "../Buttons";

export type ConfirmModalProps = {
  modalTitleText?: string | React.ReactElement;
  modalBodyText?: string | React.ReactElement;
  modalCancelText?: string | React.ReactElement;
  modalSubmitText?: string | React.ReactElement;
  isDangerous?: boolean;
  validateEnvironment?: boolean;
  modalBackground?: string;
  isOpen: boolean;
  size?: ModalProps["size"];
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  modalTitleText = "Confirm",
  modalBodyText = "Are you sure?",
  modalCancelText = "Cancel",
  modalSubmitText = "Yes",
  modalBackground = undefined,
  isDangerous = true,
  validateEnvironment = false,
  isOpen,
  size,
  onCancel,
  onConfirm,
}) => {
  const [environmentValue, setEnvironmentValue] = useState("");
  const onClose = (event: unknown, reason: string | undefined): void => {
    if (reason === "submit") {
      onConfirm();
    } else {
      onCancel();
    }
  };
  const buttonVariant = isDangerous ? "danger" : undefined;
  const headerRef = useRef(null);
  return (
    <Modal
      initialFocusRef={headerRef}
      isOpen={isOpen}
      onClose={onCancel}
      closeOnOverlayClick
      size={size}
    >
      <ModalOverlay background={modalBackground}>
        <ModalContent>
          <ModalHeader ref={headerRef} tabIndex={-1} outline="none">
            {modalTitleText}
          </ModalHeader>
          <ModalCloseButton
            tabIndex={-1}
            onClick={(e) => {
              onClose(e, "closeButton");
            }}
          />
          <ModalBody>
            {modalBodyText}
            {validateEnvironment && (
              <FormControl my="4">
                <Input
                  value={environmentValue}
                  onChange={(e) => setEnvironmentValue(e.currentTarget.value)}
                  placeholder={config.appEnv}
                  data-testid="confirm-env-input"
                />
                <FormHelperText>
                  Enter the current environment to continue
                </FormHelperText>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              variant="ghost"
              fontWeight="500"
              onClick={(e: any) => {
                onClose(e, "cancel");
              }}
              data-testid="confirmation-modal-cancel"
            >
              {modalCancelText}
            </Button>
            <Button
              variant={buttonVariant}
              disabled={
                validateEnvironment && !(environmentValue === config.appEnv)
              }
              onClick={(e: any) => {
                onClose(e, "submit");
              }}
              data-testid="confirmation-modal-submit"
            >
              {modalSubmitText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ConfirmModal;
