import { Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import { ConfirmModal } from "../../../components";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import GuideForm, { FormData } from "../GuideForm/GuideForm";

interface EditCallGuidesModalProps {
  guideIds: string[];
  isOpen: boolean;
  onClose: () => void;
}

const EditCallGuidesModal: React.FC<EditCallGuidesModalProps> = ({
  guideIds,
  isOpen,
  onClose,
}) => {
  const [formData, setFormData] = useState<FormData | null>(null);
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [, updateCallGuides, { error, loading }] = useUpdateCallGuide({
    onCompleted: () => {
      onClose();
    },
  });

  const handleSubmit = ({
    positionId,
    assignedUserIds,
  }: {
    positionId?: string;
    assignedUserIds?: string[];
  }): void => {
    setFormData({ positionId, assignedUserIds });
    onConfirmOpen();
  };

  const handleConfirm = (): void => {
    if (formData) {
      updateCallGuides({
        variables: {
          ids: guideIds,
          ...formData,
        },
      });
      onConfirmClose();
    }
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay>
          {!isConfirmOpen && (
            <GuideForm
              guideCount={guideIds.length}
              error={error}
              isLoading={loading}
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          )}
        </ModalOverlay>
      </Modal>

      <ConfirmModal
        isOpen={isConfirmOpen}
        onCancel={onConfirmClose}
        onConfirm={handleConfirm}
        modalTitleText="Confirm your updates"
        modalBodyText="Updating the interview guides will override any existing positions or interviewers assigned."
        modalSubmitText="Update"
        isDangerous={false}
        modalBackground="transparent"
      />
    </>
  );
};

export default EditCallGuidesModal;
