import useLazyLocalStorageState from "./useLazyLocalStorage";

/**
 * Wrapper around useLocalStorageState so we can swap this to DB persistence more easily later.
 */
export const useDismissable = (
  key: string
): {
  dismissed: boolean;
  dismiss: () => void;
} => {
  const [dismissed, setDismissed] = useLazyLocalStorageState<boolean>(
    `DISMISSABLE:${key}`,
    false
  );

  const dismiss = (): void => {
    setDismissed(true);
  };

  return { dismissed, dismiss };
};
