import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ListItem,
  Select,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";

import {
  Button,
  ExpandableTextArea,
  FullscreenColumn,
  FullscreenContent,
  FullscreenHeader,
  FullscreenModal,
} from "../../../components";
import { copy } from "../../../utils/clipboard";
import { CustomTemplateCustomType } from "../../graphql";
import SectionsForm from "./SectionsForm";
import { CustomTemplateInput, CustomTemplateSectionInput } from "./types";

type EditCustomTemplateFullscreenModalProps = {
  template: CustomTemplateInput | null;
  onClose: () => void;
  onDelete: (id: string) => void;
  onSave: (input: CustomTemplateInput) => void;
};

const EditCustomTemplateFullscreenModal: React.FC<
  EditCustomTemplateFullscreenModalProps
> = ({ template, onClose, onDelete, onSave }) => {
  const [customType, setCustomType] = useState<
    CustomTemplateCustomType | undefined
  >(template?.customType || CustomTemplateCustomType.Sections);
  const [customPrompt, setCustomPrompt] = useState(
    template?.customPrompt || ""
  );

  const [templateName, setTemplateName] = useState<string>(
    template?.name || ""
  );
  const [isCreatorDefault, setIsCreatorDefault] = useState<boolean>(
    template?.isCreatorDefault || false
  );
  const [sections, setSections] = useState<CustomTemplateSectionInput[]>(
    template?.sections || []
  );

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!sections) {
        return;
      }
      const item = sections[fromIndex];
      if (!item) return;
      if (toIdx >= sections.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;

      const newList = [] as Array<CustomTemplateSectionInput>;
      for (let i = 0; i < sections.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          newList.push(sections[fromIndex]);
        }
        newList.push(sections[i]);
      }
      if (toIndex === sections.length) {
        newList.push(sections[fromIndex]);
      }
      setSections(newList);
    },
    [sections]
  );

  const onAddSection = useCallback(() => {
    const id = Math.random().toString(36).substr(2, 9);
    setSections((prev) => [...prev, { id, title: "", prompt: [""] }]);
  }, []);

  const onDeleteSection = useCallback((id: string) => {
    setSections((prev) => prev.filter((section) => section.id !== id));
  }, []);

  const updateSection = (
    index: number,
    title: string,
    prompt: string
  ): void => {
    const newSections = [...sections];
    const promptArr = [prompt];
    newSections[index] = {
      ...newSections[index],
      title,
      prompt: promptArr,
    };
    setSections(newSections);
  };

  const saveTemplate = (): void => {
    onSave({
      id: template?.id,
      name: templateName,
      isCreatorDefault,
      sections,
      customType,
      customPrompt,
    });
  };

  const copyTemplateText = (): void => {
    let templateText = "";
    sections.forEach((section) => {
      templateText += `${section.title}\n\n`;
      section.prompt.forEach((p) => {
        templateText += `${p}\n`;
      });
      templateText += `\n`;
    });
    copy(templateText);
  };

  return (
    <FullscreenModal>
      <FullscreenHeader>
        <Flex height="40px" my={3} flexDir="row" alignItems="center">
          <Box fontSize="xl" fontWeight="medium">
            {template ? "Edit" : "Create"} a custom template
          </Box>
          <Button
            ml="auto"
            size="sm"
            onClick={onClose}
            variant="ghost"
            w="120px"
          >
            Cancel
          </Button>
          <Button ml={6} size="sm" onClick={saveTemplate} w="120px">
            Save
          </Button>
        </Flex>
        <Box
          height={1}
          background="linear-gradient(99.01deg, #0074DD -2.93%, #591EFF 58.85%, #9644FF 126.18%)"
        />
      </FullscreenHeader>
      <FullscreenContent>
        <FullscreenColumn flex="4" pr={8}>
          <FormControl mb="4">
            <FormLabel fontWeight="semibold">Template name</FormLabel>
            <Input
              placeholder="E.g. Screening Call"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              fontSize="sm"
              autoFocus
            />
          </FormControl>
          <FormControl my="4">
            <FormLabel fontWeight="semibold">Template Type</FormLabel>
            <Select
              onChange={(e: any) => setCustomType(e.target.value)}
              mb={3}
              fontSize="sm"
              defaultValue={customType}
            >
              <option key="SECTIONS" value="SECTIONS">
                Sections - a list of sections to cover, with descriptions
              </option>
              <option key="CUSTOM_PROMPT" value="CUSTOM_PROMPT">
                Custom - write your own prompt to fully customize the results
              </option>
            </Select>
          </FormControl>

          {customType === "SECTIONS" && (
            <SectionsForm
              sections={sections}
              updateSection={updateSection}
              onDeleteSection={onDeleteSection}
              onAddSection={onAddSection}
              handleMove={handleMove}
              copyTemplateText={copyTemplateText}
            />
          )}

          {customType === "CUSTOM_PROMPT" && (
            <FormControl my="4">
              <FormLabel fontWeight="semibold">Prompt</FormLabel>
              <ExpandableTextArea
                maxH="40dvh"
                onChange={(e) => setCustomPrompt(e.target.value)}
                value={customPrompt}
                fontSize="sm"
              />
            </FormControl>
          )}
        </FullscreenColumn>
        <FullscreenColumn
          flex="2"
          pl={8}
          borderLeft="1px solid"
          borderColor="gray.200"
        >
          <Text fontWeight="semibold" fontSize="sm" color="gray.800" mb="1">
            About Templates
          </Text>
          <UnorderedList fontSize="sm">
            <ListItem>
              <strong>Basic:</strong> Define sections to instruct the AI on what
              to include in them.
            </ListItem>
            <ListItem>
              <strong>Advanced:</strong> Directly author the prompt used by AI
              to generate your notes.
            </ListItem>
          </UnorderedList>
          <Box height="1px" background="gray.200" my={6} />

          <Text fontWeight="semibold" fontSize="sm" color="gray.800" mb="1">
            Sharing &amp; Assignment
          </Text>
          <Box px="3">
            <Checkbox
              isChecked={isCreatorDefault}
              onChange={(e) => setIsCreatorDefault(e.target.checked)}
            >
              <Text pl="2" fontSize="sm" color="gray.800">
                Set as default
              </Text>
            </Checkbox>
          </Box>
        </FullscreenColumn>
      </FullscreenContent>
    </FullscreenModal>
  );
};

export default EditCustomTemplateFullscreenModal;
