import {
  Box,
  ButtonGroup,
  Flex,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {
  HiDotsVertical,
  HiOutlineChatAlt2,
  HiOutlineDuplicate,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { IconButton, MenuButton } from "../../../components";
import {
  CallAiSummaryTemplateListItemFragment,
  useDeleteAiSummaryTemplateMutation,
  useDuplicateAiSummaryTemplateMutation,
} from "../../graphql";

type CallAiSummaryTemplateActionsProps = {
  template: CallAiSummaryTemplateListItemFragment;
};

const CallAiSummaryTemplateActions: React.FC<
  CallAiSummaryTemplateActionsProps
> = ({ template }) => {
  // TODO:
  // - Edit template: Implement
  // - Go to example interview: Implement
  // - Copy template: Open the edit modal?

  const toast = useToast();

  const [deleteTemplate] = useDeleteAiSummaryTemplateMutation({
    update(cache, { data }) {
      const deletedTemplate = data?.deleteAiSummaryTemplate?.template;

      if (deletedTemplate) {
        cache.evict({
          id: cache.identify(deletedTemplate),
        });
        cache.gc();
      }
    },
    onCompleted() {
      toast({
        title: "Template deleted",
        status: "success",
      });
    },
  });

  const [copyTemplate] = useDuplicateAiSummaryTemplateMutation({
    // Using refetch instead of cache update to avoid complexity of sorting
    refetchQueries: ["CurrentUserMyCallSummaryTemplates"],
    onCompleted() {
      toast({
        title: "Template copied to My Templates",
        status: "success",
      });
    },
  });

  return (
    <Flex width="100%" alignItems="center" justifyContent="end">
      <ButtonGroup variant="ghost" size="md" ml="auto">
        {template.canEdit && (
          <IconButton
            aria-label="Edit template"
            colorScheme="blackAlpha"
            color="gray.900"
            icon={<HiOutlinePencil />}
          />
        )}
        <IconButton
          aria-label="Copy template"
          colorScheme="blackAlpha"
          color="gray.900"
          icon={<HiOutlineDuplicate />}
          onClick={() => {
            copyTemplate({ variables: { id: template.id } });
          }}
        />
        <Menu>
          <MenuButton as={IconButton} icon={<HiDotsVertical />} />
          <MenuList>
            <MenuItem value="example">
              <HiOutlineChatAlt2 />
              <Box flex="1" ml="2">
                Go to example interview
              </Box>
            </MenuItem>
            {template.canDelete && (
              <>
                <MenuDivider />
                <MenuItem
                  value="delete"
                  color="red.500"
                  onClick={() =>
                    deleteTemplate({ variables: { id: template.id } })
                  }
                >
                  <HiOutlineTrash />
                  <Box flex="1" ml="2">
                    Delete
                  </Box>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </ButtonGroup>
    </Flex>
  );
};

export default CallAiSummaryTemplateActions;
