import React from "react";

import { CurrentUserFragment, usePlanCustomTemplateQuery } from "../../graphql";
import PlanJobDescriptionSettingsForm from "./PlanJobDescriptionSettingsForm";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface PlanJobDescriptionSettingsProps {
  currentUser: CurrentUserFragment;
}

const PlanJobDescriptionSettings: React.FC<PlanJobDescriptionSettingsProps> = ({
  currentUser,
}) => {
  const { data, loading } = usePlanCustomTemplateQuery();
  if (loading || !data?.planCustomTemplate) return null;
  const { id: templateId, sections } = data?.planCustomTemplate || {};
  return (
    <SettingsPageContainer heading="Job descriptions" maxW="776px">
      <PlanJobDescriptionSettingsForm
        currentUser={currentUser}
        templateId={templateId}
        sections={sections}
      />
    </SettingsPageContainer>
  );
};

export default PlanJobDescriptionSettings;
