import { Box, Checkbox, Tag, TagLabel } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Row } from "react-table";

import {
  AtsIcon,
  RouterLink,
  SortableTable,
  SortableTableColumn,
  useToast,
} from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { CallGuideListItemFragment } from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { CallGuideMenu } from "..";
import SharingLabel from "./SharingLabel";

interface CallGuideListProps {
  callGuides: Array<CallGuideListItemFragment>;
  loading?: boolean;
  showSharing?: boolean;
  showOwner?: boolean;
  showPosition?: boolean;
  showRemove?: boolean;
  onSelectionChange?: (selectedGuides: string[]) => void;
}

const CallGuideList: React.FC<CallGuideListProps> = ({
  callGuides,
  loading = false,
  showSharing = false,
  showOwner = false,
  showPosition = false,
  showRemove = false,
  onSelectionChange,
}) => {
  const [selectedGuides, setSelectedGuides] = useState<Set<string>>(new Set());
  const guideStageAssignment = useFeatureFlag("guide-stage-assignment");

  const toast = useToast();
  const [updateCallGuide] = useUpdateCallGuide({
    onError: () =>
      toast({ status: "error", description: "Error updating guide" }),
  });

  const toggleIsShared = useCallback(
    (id: string, visibility: string) =>
      updateCallGuide({
        variables: {
          id,
          visibility: visibility === "PUBLIC" ? "PRIVATE" : "PUBLIC",
        },
      }),
    [updateCallGuide]
  );

  const templateSortBy = React.useCallback(
    (
      rowA: Row<CallGuideListItemFragment>,
      rowB: Row<CallGuideListItemFragment>,
      id: "isTemplate",
      desc: boolean
    ): number => {
      if (rowA.original[id] && !rowB.original[id]) return -1;
      if (rowB.original[id] && !rowA.original[id]) return 1;
      return 0;
    },
    []
  );

  const handleSelection = useCallback(
    (guideId: string, isSelected: boolean) => {
      setSelectedGuides((prev) => {
        const next = new Set(prev);
        if (isSelected) {
          next.add(guideId);
        } else {
          next.delete(guideId);
        }
        onSelectionChange?.(Array.from(next));
        return next;
      });
    },
    [onSelectionChange]
  );

  const handleSelectAll = useCallback(
    (isSelected: boolean) => {
      const next = new Set<string>();
      if (isSelected) {
        callGuides.forEach((guide) => next.add(guide.id));
      }
      setSelectedGuides(next);
      onSelectionChange?.(Array.from(next));
    },
    [callGuides, onSelectionChange]
  );

  if (!loading && callGuides.length < 1) {
    return (
      <Box mt={2} alignItems="center">
        {"No guides yet. "}
        <RouterLink to="/guides/new">Create your own</RouterLink>.
      </Box>
    );
  }

  const stageColumn: Array<SortableTableColumn<CallGuideListItemFragment>> =
    guideStageAssignment
      ? [
          {
            Header: "Stage",
            accessor: (guide) => guide.interviewStage?.title,
            id: "stage.title",
            show: showPosition,
            Cell: ({
              row: { original: callGuide },
            }: {
              row: { original: CallGuideListItemFragment };
            }) => {
              if (!callGuide.interviewStage) {
                return null;
              }
              return <>{callGuide.interviewStage?.title}</>;
            },
          },
        ]
      : [];

  const columnsStart: Array<SortableTableColumn<CallGuideListItemFragment>> = [
    {
      id: "selection",
      Header: () => (
        <Checkbox
          isChecked={selectedGuides.size === callGuides.length}
          isIndeterminate={
            selectedGuides.size > 0 && selectedGuides.size < callGuides.length
          }
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => (
        <Checkbox
          isChecked={selectedGuides.has(callGuide.id)}
          onChange={(e) => handleSelection(callGuide.id, e.target.checked)}
        />
      ),
      width: 40,
    },
    {
      Header: "Guide Name",
      accessor: "name",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => (
        <RouterLink to={`/guide/${callGuide.id}`}>
          {callGuide.name ?? "Untitled"}
        </RouterLink>
      ),
    },
    {
      Header: "Position",
      accessor: (guide) => guide.position?.displayTitle,
      id: "position.displayTitle",
      show: showPosition,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => {
        if (!callGuide.position) {
          return null;
        }
        return (
          <RouterLink to={`/position/${callGuide.position?.id}`}>
            {callGuide.position?.displayTitle}
          </RouterLink>
        );
      },
    },
  ];

  const columnsEnd: Array<SortableTableColumn<CallGuideListItemFragment>> = [
    {
      Header: "Questions",
      accessor: "cues",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => <>{callGuide.cues?.length}</>,
    },
    {
      Header: "Assigned Interviewers",
      accessor: (guide) => guide.assignedUsers.length,
    },
    {
      Header: "Template",
      accessor: "isTemplate",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sortType: templateSortBy,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) =>
        callGuide.isTemplate ? (
          <Tag
            variant="solid"
            size="sm"
            borderRadius="full"
            backgroundColor="blue.50"
            color="blue.600"
          >
            <TagLabel pl="1" pr="1">
              Template
            </TagLabel>
          </Tag>
        ) : null,
    },
    {
      Header: "Sharing",
      accessor: "visibility",
      show: showSharing,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => (
        <SharingLabel
          id={callGuide.id}
          visibility={callGuide.visibility}
          onClick={toggleIsShared}
        />
      ),
    },
    {
      Header: "Owner",
      accessor: (guide) => guide.creator?.fullName,
      id: "creator.fullName",
      show: showOwner,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => {
        if (callGuide.creator) {
          return <>{callGuide.creator.fullName}</>;
        }
        return null;
      },
    },
    {
      Header: "ATS",
      sortType: "none",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => {
        return (
          <AtsIcon
            isGreenhouse={!!callGuide.greenhouseId}
            isLever={!!callGuide.leverFeedbackTemplateId}
            isAshby={!!callGuide.ashbyFeedbackFormId}
            isSmartrecruiters={!!callGuide.smartrecruitersCriteriaId}
          />
        );
      },
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => <>{formatRelativeDate(callGuide.updatedAt)}</>,
    },
    {
      Header: " ", // Empty header for the menu (must be a space)
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => <CallGuideMenu callGuide={callGuide} showRemove={showRemove} />,
    },
  ];

  const columns = [...columnsStart, ...stageColumn, ...columnsEnd];

  return (
    <SortableTable
      columns={columns}
      loading={loading}
      data={callGuides}
      initialSort={{ id: "updatedAt", desc: true }}
      width="100%"
    />
  );
};

CallGuideList.displayName = "CallGuideList";
export default CallGuideList;
