import { Box, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { useParams, useSearchParams } from "react-router-dom";

import {
  Button,
  LoadingIndicator,
  Spinner,
  useToast,
} from "../../../components";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import ViewportPageContent from "../../../plan/layouts/ViewportPageContent";
import { formatDetails } from "../../../utils/call";
import { copy } from "../../../utils/clipboard";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { formatSegment } from "../../../utils/transcript";
import {
  CallAiSummaryFormat,
  CallStatus,
  useAuditLogItemExistsQuery,
  useCallAiSummaryLazyQuery,
  useCallBetaQuery,
  useUpdateCallAiSummaryFormatMutation,
  useViewCallMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";
import useMouseHasMoved from "../../hooks/useMouseHasMoved";
import {
  AskPanel,
  introMessageForInterviews,
  suggestedInterviewMessages,
} from "../Candidate/Ask/AskPanel";
import { RecordingLayout, Transcript } from "../Recording";
import { buildProgressMessage } from "../Recording/Transcript/utils";
import useVideoState from "../Video/useVideoState";
import CallDetailsHeader from "./beta/CallDetailsHeader";
import CallVideo from "./beta/CallVideo";
import { useCallErrorView } from "./beta/useCallErrorView";
import { CallTab, isCallTab } from "./beta/useCallSidebar";
import useCallSpeakerOptions from "./beta/useCallSpeakerOptions";
import useCallTranscript from "./beta/useCallTranscript";
import CallErrorState from "./CallErrorState";
import CallSidebar from "./CallSidebar";
import ClipModal from "./Clip/ClipModalBeta";
import { ClipRange } from "./Clip/types";
import { clipDefaultEndTime } from "./Clip/utils";
import { VideoDisplayMode } from "./types";
import useMediaPlayer from "./useMediaPlayer";
import useStartTime from "./useStartTime";
import useVisitorAnalytics from "./useVisitorAnalytics";

type InterviewViewBetaProps = {
  alternateCallSelect?: JSX.Element;
  disableAutofill?: boolean;
  notesForCurrentUserOnly?: boolean;
};

const InterviewViewBeta: React.FC<InterviewViewBetaProps> = ({
  alternateCallSelect,
  disableAutofill,
  notesForCurrentUserOnly = false,
}) => {
  const { callId } = useParams() as { callId: string };
  const [searchParams, setSearchParams] = useSearchParams();
  // DO NOT REMOVE
  // We use this for sales demos.
  const redactedRecording = searchParams.has("redacted");
  const tabFromUrl = searchParams.get("tab");
  const defaultTab =
    tabFromUrl && isCallTab(tabFromUrl) ? tabFromUrl : CallTab.NOTES;
  const toast = useToast();

  const isExtension = useIsExtension();
  const sendGAEvent = useSendGAEvent();

  // Check the audit log for the given item and see what state to display
  const { data: auditData, loading: auditLoading } = useAuditLogItemExistsQuery(
    {
      variables: { id: callId, name: "delete_call", property: "call_id" },
    }
  );

  // Query for the call at hand
  const { loading, error, data, stopPolling } = useCallBetaQuery({
    variables: {
      id: callId,
      // As above, DO NOT REMOVE
      redactedRecording,
    },
    pollInterval: 5000,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const transcriptQuery = useCallTranscript({
    callId,
    notesForCurrentUserOnly,
  });

  const { speakerOptions } = useCallSpeakerOptions({ callId });

  /**
   * The call that forms the basis for this interview to display
   */
  const call = data?.call;

  const [clipRange, setClipRange] = useState<ClipRange | null>(null);
  // Set up the media player & video
  const [mediaPlayerRef, listeners, player] = useMediaPlayer({
    enabled: !clipRange,
  });

  // Set up audio/video player controlls
  const { seek, time, play, canPlay, duration } = player;
  const seekAndPlay = useCallback(
    (t: number): void => {
      seek(t);
      if (!autoScrollEnabled) {
        setAutoScrollEnabled(true);
      }
      play();
    },
    [seek, autoScrollEnabled]
  );

  useEffect(() => {
    if (clipRange) {
      player.pause();
    }
  }, [clipRange]);

  const startTime = useStartTime(duration);

  useEffect(() => {
    if (canPlay) {
      seek(startTime);
    }
  }, [canPlay, startTime, seek]);

  const onCreateClipClick = (): void => {
    setClipRange({
      start: time,
      end: clipDefaultEndTime(call?.duration, time),
    });
  };

  const [currentTab, setCurrentTab] = useState(defaultTab);
  useEffect(() => {
    searchParams.set("tab", currentTab);
    setSearchParams(searchParams.toString(), { replace: true });
  }, [currentTab]);

  let videoDisplayMode = VideoDisplayMode.NO_RECORDING;
  if (call?.streamableAudio && !call?.streamableVideo) {
    videoDisplayMode = VideoDisplayMode.AUDIO;
  } else if (call?.streamableVideo) {
    videoDisplayMode = VideoDisplayMode.VIDEO;
  } else if (call?.isProcessing) {
    videoDisplayMode = VideoDisplayMode.PROCESSING;
  } else if (call?.status === CallStatus.InProgress) {
    videoDisplayMode = VideoDisplayMode.IN_PROGRESS;
  }

  const {
    containerRef,
    videoCallbackRef,
    fullScreenControls,
    pipControls,
    isVideoVisible,
    hideVideo,
    focusVideo,
  } = useVideoState(videoDisplayMode);

  /**
   * The audio or video content URL to stream from
   */
  const url = call?.streamableVideo
    ? call?.streamableVideo?.url
    : call?.streamableAudio?.url;
  const [mediaSrc, setMediaSrc] = useState(url);
  if (mediaSrc?.split("?")[0] !== url?.split("?")[0]) {
    setMediaSrc(url);
  }

  // Log views to audit log & activity feed
  const [viewCall, { called: callViewLogged }] = useViewCallMutation();
  const mouseHasMoved = useMouseHasMoved();
  useEffect(() => {
    if (call && mouseHasMoved && !callViewLogged) {
      viewCall({ variables: { callId } });
    }
  }, [call, mouseHasMoved, callViewLogged]);

  // Log the view to segment
  useVisitorAnalytics(
    callViewLogged,
    isExtension ? "extension" : "app",
    call?.transcriptionStatus as string,
    call?.interviewerIds,
    call?.id
  );

  const errorView = useCallErrorView({
    error,
    call,
    auditLogItem: auditData?.auditLogItemExists,
  });

  const onCopyTranscript = useCallback((): void => {
    if (!call) {
      return;
    }
    sendGAEvent("copy_transcript", "call_review");
    const callDetails = formatDetails(call);
    const speakerLabels = call.speakers.map((speaker) => speaker.label);
    const transcriptText =
      transcriptQuery.data.transcript
        ?.map((segment) => formatSegment(speakerLabels, segment))
        .reduce((previous, current) => `${previous}\n\n${current}`, "") ?? "";
    const text = `${callDetails}\n\n${transcriptText}`;
    copy(text);
    toast({
      title: "Transcript copied to clipboard",
      status: "success",
    });
  }, [call, transcriptQuery, toast]);

  const [fetchSummary] = useCallAiSummaryLazyQuery({});
  const [updateSummaryFormat] = useUpdateCallAiSummaryFormatMutation({});
  const writeupEnabled = useFeatureFlag("ai-summary:writeup");
  // Used to make the AI Summary experience feel more seamless. It will generate
  // a missing summary, so by the time the user gets to the tab, it should be
  // almost finished generating
  useEffect(() => {
    if (writeupEnabled && mouseHasMoved) {
      fetchSummary({
        variables: {
          callId,
          format: CallAiSummaryFormat.Writeup,
        },
        fetchPolicy: "network-only",
        onCompleted: (res) => {
          if (!res.callAiSummary?.id) {
            updateSummaryFormat({
              variables: {
                callId,
                format: CallAiSummaryFormat.Writeup,
                targetSpeakerTags: [],
                customTopics: [],
              },
            });
          }
        },
      });
    }
  }, []);

  const askDisclosure = useDisclosure();
  const ask2Enabled = useFeatureFlag("interview_ask:v2");
  const isSmallScreen = useIsSmallScreen();

  // If the call is loading or we're still figuring out if it was deleted
  // or never existed etc, show loading indicator
  if (loading || auditLoading || transcriptQuery.data.loading) {
    return <LoadingIndicator pt="10vh" />;
  }

  // If something went wrong loading the call or there is no call at all,
  // set up the appropriate view and don't try to get new data
  if (errorView || !call) {
    stopPolling();

    return (
      <Flex flexDirection="column" overflowY="hidden">
        <CallErrorState view={errorView} />
      </Flex>
    );
  }

  let transcriptSection = null;
  if (call.isProcessing || call.status === CallStatus.InProgress) {
    let recordingMessage;
    let hasRecording = false;
    if (call.status !== CallStatus.InProgress) {
      hasRecording = Boolean(call.streamableAudio || call.streamableVideo);
      recordingMessage = hasRecording
        ? "Transcribing recording..."
        : "Processing and transcribing recording…";
    } else {
      recordingMessage = "Interview is in progress...";
    }
    transcriptSection = (
      <Flex flexDirection="column" align="center" py="10">
        <Spinner width="48px" height="48px" />
        <Box
          fontWeight="bold"
          color="gray.800"
          fontSize="lg"
          textAlign="center"
          marginTop={3}
        >
          {recordingMessage}
          {call.duration && (
            <Box fontSize="md" fontWeight="400" mt={2} px={6}>
              {buildProgressMessage(call.endTime)}
            </Box>
          )}
          {hasRecording && (
            <Box width="100%" fontSize="lg" my={6} textAlign="center">
              {`Click "Play" to start ${
                call.streamableVideo ? "watching" : "listening"
              } while we finish transcribing the interview.`}
            </Box>
          )}
          {call.status === CallStatus.InProgress && (
            <Box fontSize="md" fontWeight="400" mt={2} px={6}>
              When the interview is completed and processed, you can play back
              the recording and review the transcript.
            </Box>
          )}
        </Box>
      </Flex>
    );
  } else {
    transcriptSection = (
      <Transcript
        h="100%"
        callId={call.id}
        speakers={call.speakers}
        player={player}
        autoScrollEnabled={autoScrollEnabled}
        setAutoScrollEnabled={setAutoScrollEnabled}
        onCreateClipClick={setClipRange}
        onCopyTranscript={onCopyTranscript}
        isVideoCall={videoDisplayMode === VideoDisplayMode.VIDEO}
        isVideoVisible={isVideoVisible}
        hideVideo={hideVideo}
        focusVideo={focusVideo}
        speakerOptions={speakerOptions}
        canChangeSpeaker={call.canEdit}
        {...transcriptQuery.data}
      />
    );
  }

  // Stop polling if the call is no longer processing
  if (!call.isProcessing) {
    stopPolling();
  }

  const actionButtons = ask2Enabled ? (
    <Button
      ml="auto"
      bg="purple.450"
      size="sm"
      colorScheme="purple"
      leftIcon={<Icon as={HiOutlineSparkles} />}
      onClick={askDisclosure.onOpen}
      flexShrink={0}
      data-tour-id="interview-page-ask-button"
    >
      {isSmallScreen ? "Ask" : "Ask Assistant"}
    </Button>
  ) : undefined;

  if (isExtension && askDisclosure.isOpen) {
    return (
      <ViewportPageContent padding="0 0">
        <AskPanel
          requestPath={`/interview/${call.id}/ask2`}
          onClose={() => {
            sendGAEvent("ask_close", "call_review");
            askDisclosure.onClose();
          }}
          suggestedMessages={suggestedInterviewMessages}
          introMessage={introMessageForInterviews}
          eventCategory="call_review"
        />
      </ViewportPageContent>
    );
  }

  return (
    <RecordingLayout
      header={<CallDetailsHeader call={call} actionButtons={actionButtons} />}
      video={
        <>
          <Box data-testid="call-details-header-title">
            {alternateCallSelect}
          </Box>
          <CallVideo
            mediaPlayerRef={mediaPlayerRef}
            listeners={listeners}
            videoDisplayMode={videoDisplayMode}
            mediaSrc={mediaSrc}
            call={call}
            onSeek={seekAndPlay}
            onCreateClipClick={onCreateClipClick}
            player={player}
            isVideoVisible={isVideoVisible}
            containerRef={containerRef}
            videoRef={videoCallbackRef}
            hideVideo={hideVideo}
            fullScreenControls={fullScreenControls}
            pipControls={pipControls}
            speakerOptions={speakerOptions}
            notesForCurrentUserOnly={notesForCurrentUserOnly}
          />
        </>
      }
      transcript={transcriptSection}
      sidebar={
        <CallSidebar
          call={call}
          onSeek={seekAndPlay}
          onCreateClipClick={onCreateClipClick}
          setClipRange={setClipRange}
          currentTab={currentTab}
          player={player}
          onTabChange={setCurrentTab}
          autoScrollEnabled={autoScrollEnabled}
          setAutoScrollEnabled={setAutoScrollEnabled}
          isVideoCall={videoDisplayMode === VideoDisplayMode.VIDEO}
          isVideoVisible={isVideoVisible}
          hideVideo={hideVideo}
          focusVideo={focusVideo}
          disableAutofill={disableAutofill}
          notesForCurrentUserOnly={notesForCurrentUserOnly}
        />
      }
    >
      {clipRange && mediaSrc && (
        <ClipModal
          callId={call.id}
          mediaSrc={mediaSrc}
          initialClipRange={clipRange}
          onClipCreated={() => setCurrentTab(CallTab.CLIPS)}
          onClose={() => setClipRange(null)}
          positionId={call.position?.id}
          videoDisplayMode={videoDisplayMode}
        />
      )}
      {/* Ask sidepanel is always present on the DOM because it needs to have DOM presence for open/close transitions */}
      <Flex
        width="450px"
        height="100vh"
        position="fixed"
        top="0"
        right={askDisclosure.isOpen ? 0 : "-450px"}
        transition="right 0.2s ease-in"
        bg="white"
        zIndex="5"
      >
        <AskPanel
          requestPath={`/interview/${call.id}/ask2`}
          onClickTimestamp={seekAndPlay}
          onClose={() => {
            sendGAEvent("ask_close", "call_review");
            askDisclosure.onClose();
          }}
          hidden={!askDisclosure.isOpen}
          suggestedMessages={suggestedInterviewMessages}
          introMessage={introMessageForInterviews}
          eventCategory="call_review"
        />
      </Flex>
    </RecordingLayout>
  );
};

export default InterviewViewBeta;
