import { useEventListener } from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";

import { useSendGAEvent } from "../../../utils/googleAnalytics";

// see https://www.dr-lex.be/info-stuff/volumecontrols.html#summary
const VOLUME_EXPONENT = 3;

export type CallbackRef = (element: HTMLMediaElement | null) => void;

type MediaListener = (
  event: React.SyntheticEvent<HTMLMediaElement, Event>
) => void;

export interface MediaListeners {
  onPlaying: MediaListener;
  onPause: MediaListener;
  onEnded: MediaListener;
  onWaiting: MediaListener;
  onDurationChange: MediaListener;
  onLoadedMetadata: MediaListener;
  onCanPlay: MediaListener;
  onSeeked: MediaListener;
  onTimeUpdate: MediaListener;
  onRateChange: MediaListener;
  onVolumeChange: MediaListener;
}

export interface MediaPlayerInterface {
  elementReady: boolean;
  playing: boolean;
  waiting: boolean;
  time: number;
  duration: number | undefined;
  canPlay: boolean;
  volume: number;
  muted: boolean;
  playbackRate: number;
  play(): void;
  pause(): void;
  seek(time: number): void;
  skipForward(amount: number): void;
  skipBack(amount: number): void;
  setVolume(volume: number): void;
  mute(muted: boolean): void;
  setPlaybackRate(rate: number): void;
  togglePlayback(): void;
}

const useMediaPlayer = (
  { enabled, ...listeners }: Partial<MediaListeners & { enabled: boolean }> = {
    enabled: true,
  }
): [CallbackRef, MediaListeners, MediaPlayerInterface] => {
  const mediaElementRef = useRef<HTMLMediaElement | null>(null);
  const sendGAEvent = useSendGAEvent();
  const mediaElement = mediaElementRef.current;
  const [playing, setPlaying] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [duration, setDuration] = useState<number | undefined>();
  const [canPlay, setCanPlay] = useState(false);
  const [time, setTime] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [previousVolume, setPreviousVolume] = useState(1.0);
  const [muted, setMuted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.0);

  const callbackRef: CallbackRef = useCallback((element) => {
    mediaElementRef.current = element;
  }, []);

  const onPlaying: MediaListener = () => {
    setWaiting(false);
    setPlaying(true);
  };
  const onPause: MediaListener = () => setPlaying(false);
  const onEnded: MediaListener = () => setPlaying(false);
  const onWaiting: MediaListener = (e) => setWaiting(true);
  const updateDuration: MediaListener = (e) => {
    setPlaying(false);
    setDuration(
      Number.isNaN(e.currentTarget.duration)
        ? undefined
        : e.currentTarget.duration
    );
  };
  const updateCanPlay: MediaListener = (e) => {
    setCanPlay(true);
  };
  const updateTime: MediaListener = (e) => {
    setTime(e.currentTarget.currentTime ?? 0);
  };
  const onCanPlay: MediaListener = (e) => {
    updateTime(e);
    updateCanPlay(e);
  };
  const onRateChange: MediaListener = (e) => {
    const newPlaybackRate = e.currentTarget.playbackRate;
    // Ignore rate 0, it triggers rerenders and doesn't impact behavior.
    if (newPlaybackRate === 0) {
      return;
    }
    setPlaybackRate(newPlaybackRate);
  };
  const onVolumeChange: MediaListener = (e) => {
    const newVolume = e.currentTarget.volume;
    setVolume(newVolume ** (1 / VOLUME_EXPONENT));
    setMuted(newVolume === 0.0);
  };

  const play = useCallback(() => {
    sendGAEvent("playback_control", "call_review", "player_play");
    mediaElement?.play();
  }, [mediaElement]);

  const pause = useCallback(() => {
    if (!mediaElement) {
      return;
    }
    sendGAEvent("playback_control", "call_review", "player_pause");
    const isPlaying =
      mediaElement.currentTime > 0 &&
      !mediaElement.paused &&
      !mediaElement.ended &&
      mediaElement.readyState > 2;
    if (isPlaying) {
      mediaElement.pause();
    }
  }, [mediaElement]);

  const togglePlayback = useCallback(() => {
    if (playing) {
      pause();
    } else {
      play();
    }
  }, [playing, pause, play]);

  const spacebarPlay = useCallback(
    (e: Event): void => {
      const keyboardEvent = e as KeyboardEvent;
      if (
        keyboardEvent.code === "Space" &&
        !["input", "textarea"].includes(
          document.activeElement?.nodeName.toLowerCase() ?? ""
        ) &&
        !document.activeElement?.attributes.getNamedItem("contenteditable")
      ) {
        sendGAEvent("playback_control", "call_review", "spacebar");
        e.preventDefault();
        if (enabled) {
          togglePlayback();
        }
      }
    },
    [playing, play, pause, enabled]
  );

  useEventListener("keydown", spacebarPlay);

  return [
    callbackRef,
    {
      onPlaying,
      onPause,
      onEnded,
      onWaiting,
      onDurationChange: updateDuration,
      onLoadedMetadata: (e) => {
        listeners.onLoadedMetadata?.(e);
        return updateDuration(e);
      },
      onCanPlay,
      onTimeUpdate: updateTime,
      onSeeked: updateTime,
      onRateChange,
      onVolumeChange,
    },
    {
      elementReady: mediaElement !== null,
      playing,
      waiting,
      time,
      duration,
      canPlay,
      volume,
      muted,
      playbackRate,
      play,
      pause,
      seek: useCallback(
        (time: number) => {
          if (!mediaElement) {
            return;
          }
          sendGAEvent("playback_control", "call_review", "player_seek");
          const endTime = Number.isNaN(mediaElement.duration)
            ? 0
            : mediaElement.duration - 0.1;
          mediaElement.currentTime = time < 0 ? 0 : Math.min(time, endTime);
        },
        [mediaElement]
      ),
      skipForward: useCallback(
        (amount: number) => {
          if (!mediaElement) {
            return;
          }
          sendGAEvent("playback_control", "call_review", "skip_forward");
          let currentTime = mediaElement.currentTime + amount;
          if (Number.isFinite(mediaElement.duration)) {
            currentTime = Math.min(currentTime, mediaElement.duration);
          }
          mediaElement.currentTime = currentTime;
        },
        [mediaElement]
      ),
      skipBack: useCallback(
        (amount: number) => {
          if (!mediaElement) {
            return;
          }
          sendGAEvent("playback_control", "call_review", "skip_back");
          mediaElement.currentTime = Math.max(
            mediaElement.currentTime - amount,
            0
          );
        },
        [mediaElement]
      ),
      setVolume: useCallback(
        (volume: number) => {
          if (!mediaElement) {
            return;
          }
          sendGAEvent("playback_control", "call_review", "set_volume");
          mediaElement.volume = volume ** VOLUME_EXPONENT;
        },
        [mediaElement]
      ),
      mute: useCallback(
        (muted: boolean) => {
          if (!mediaElement) {
            return;
          }
          sendGAEvent(
            "playback_control",
            "call_review",
            muted ? "mute" : "unmute"
          );
          if (muted) {
            setPreviousVolume(volume);
            mediaElement.volume = 0.0;
          } else {
            mediaElement.volume = previousVolume ** VOLUME_EXPONENT;
          }
        },
        [mediaElement, previousVolume, volume]
      ),
      setPlaybackRate: useCallback(
        (rate: number) => {
          if (!mediaElement) {
            return;
          }
          sendGAEvent("playback_control", "call_review", "speed_change");
          mediaElement.playbackRate = rate;
        },
        [mediaElement]
      ),
      togglePlayback,
    },
  ];
};

export default useMediaPlayer;
