import {
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi2";

import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import { CallBetaFragment, TranscriptionStatus } from "../../../../graphql";
import useFeatureFlag from "../../../../graphql/hooks/useFeatureFlag";
import { Ask } from "./Ask/Ask";
import { useAsk2Enabled } from "./Ask/useAsk2Enabled";
import { useAskEnabled } from "./Ask/useAskEnabled";
import { Notes } from "./Notes/Notes";
import { Summary } from "./Summary/Summary";
import SummaryComingSoon from "./Summary/SummaryComingSoon";

type AiTabProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "transcriptionStatus"
    | "aiNotesFormat"
    | "aiNotesSupportedFormats"
    | "duration"
  >;
  hasLabeledCandidate: boolean;
  onClickTimestamp(t: number): void;
};

const useTabNames = (): string[] => {
  const askEnabled = useAskEnabled();
  const ask2Enabled = useAsk2Enabled();
  // Ask2 disables Ask1 because it moves chat outside of the sidebar,
  // so it's only "Assistant" if exclusively Ask1 is enabled
  if (ask2Enabled || !askEnabled) {
    return ["Notes", "Summary", "Email"];
  }
  return ["Notes", "Summary", "Assistant"];
};

const AiTab: React.FC<AiTabProps> = ({
  call,
  hasLabeledCandidate,
  onClickTimestamp,
}) => {
  const sendGAEvent = useSendGAEvent();
  const [tabIndex, setTabIndexState] = useState(0);
  const writeupEnabled = useFeatureFlag("ai-summary:writeup");
  const quickActionsEnabled = useFeatureFlag("ai-tabs:quick-actions");
  const askEnabled = useAskEnabled();
  const TAB_NAMES = useTabNames();
  const setTabIndex = (idx: number): void => {
    if (idx !== tabIndex) {
      setTabIndexState(idx);
      sendGAEvent(
        "sidebar_tab_change",
        "call_review",
        TAB_NAMES[idx],
        undefined,
        {
          callId: call.id,
        }
      );
      LogRocket.track("ai-notes-tab-change", { tab: TAB_NAMES[idx] });
    }
  };

  return (
    <Tabs
      display="flex"
      flexDir="column"
      height="100%"
      index={tabIndex}
      onChange={setTabIndex}
      isLazy
      lazyBehavior="keepMounted"
    >
      <TabList
        alignItems="center"
        bg="white"
        borderRadius="0"
        borderBottom="1px"
        display="flex"
        justifyContent="flex-start"
        paddingBottom="2px"
        overflow="hidden"
        mx="5"
      >
        <Tab
          _hover={{
            backgroundColor: "gray.100",
          }}
          _selected={{
            color: "purple.600 !important",
            fontWeight: "600",
            border: "3px solid",
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            borderColor: "purple.600 !important",
            borderRadius: "none",
          }}
          background="white"
          color="purple.600"
          whiteSpace="nowrap"
          borderRadius="none"
          data-testid="ai-notes-tab"
          h="10"
          key="ai-notes"
          px="2"
          flex="1"
          maxW="115px"
          fontWeight="500"
        >
          <Icon mr={1} as={HiOutlineSparkles} />
          {TAB_NAMES[0]}
        </Tab>
        <Tab
          _hover={{
            backgroundColor: "gray.100",
          }}
          _selected={{
            color: "purple.600 !important",
            fontWeight: "600",
            border: "3px solid",
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            borderColor: "purple.600 !important",
            borderRadius: "none",
          }}
          background="white"
          color="purple.600"
          whiteSpace="nowrap"
          borderRadius="none"
          data-testid="ai-summary-tab"
          h="10"
          key="ai-summary"
          px="2"
          flex="1"
          maxW="115px"
          fontWeight="500"
        >
          <Icon mr={1} as={HiOutlineSparkles} />
          {TAB_NAMES[1]}
        </Tab>
        <Tab
          _hover={{
            backgroundColor: "gray.100",
          }}
          _selected={{
            color: "purple.600 !important",
            fontWeight: "600",
            border: "3px solid",
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            borderColor: "purple.600 !important",
            borderRadius: "none",
          }}
          background="white"
          color="purple.600"
          whiteSpace="nowrap"
          borderRadius="none"
          data-testid="ai-ask-tab"
          h="10"
          key="ai-ask"
          px="2"
          flex="1"
          maxW="115px"
          fontWeight="500"
          visibility={askEnabled || quickActionsEnabled ? "visible" : "hidden"}
        >
          <Icon mr={1} as={HiOutlineSparkles} />
          {TAB_NAMES[2]}
        </Tab>
      </TabList>
      <TabPanels overflowY="auto" flex="1" px="5">
        <TabPanel key="ai-notes">
          <Notes
            call={call}
            hasLabeledCandidate={hasLabeledCandidate}
            onClickTimestamp={onClickTimestamp}
          />
        </TabPanel>
        <TabPanel key="ai-summary" pb="5">
          {!writeupEnabled && <SummaryComingSoon />}
          {writeupEnabled && (
            <Summary
              callId={call.id}
              transcriptAvailable={
                call.transcriptionStatus === TranscriptionStatus.Completed
              }
              callDuration={call.duration}
              onClickTimestamp={onClickTimestamp}
            />
          )}
        </TabPanel>
        <TabPanel key="ai-ask" pb="5" height="100%">
          <Ask callId={call.id} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AiTab;
