import React from "react";

import { useToast } from "../../../components";
import { useOrgFeatureAccess } from "../../../hooks/useOrgFeatureAccess";
import {
  FeatureName,
  OrganizationFragment,
  useUpdateOrganizationGoogleMeetMutation,
} from "../../graphql";
import GoogleMeetSettingsForm from "./GoogleMeetSettingsForm";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface GoogleMeetSettingsProps {
  organization: OrganizationFragment;
}

const GoogleMeetSettings: React.FC<GoogleMeetSettingsProps> = ({
  organization,
}) => {
  const toast = useToast();
  const [updateGoogleMeetSettings, { loading, error }] =
    useUpdateOrganizationGoogleMeetMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationGoogleMeet?.organization) {
          toast({
            status: "success",
            title: "Google Meet Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Google Meet Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  const virtualOnsiteAccess = useOrgFeatureAccess(
    FeatureName.VirtualOnsiteSplitting,
    organization
  );

  return (
    <SettingsPageContainer heading="Google Meet notetaker">
      <GoogleMeetSettingsForm
        organizationName={organization.name}
        googleMeetEnabled={organization.googleMeetEnabled}
        googleMeetRecordingDisclaimer={
          organization.googleMeetRecordingDisclaimer
        }
        googleMeetRecordingDisclaimerType={
          organization.googleMeetRecordingDisclaimerType
        }
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        defaultDisplayName={organization.googleMeetDisplayName}
        showVirtualOnsiteSetting={virtualOnsiteAccess?.isVisible === true}
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledGoogleMeet}
        isLoading={loading}
        error={error}
        onSubmit={(formData) =>
          updateGoogleMeetSettings({ variables: formData })
        }
      />
    </SettingsPageContainer>
  );
};

export default GoogleMeetSettings;
