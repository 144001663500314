import { ApolloError, MutationTuple } from "@apollo/client";

import {
  CallGuideVisibility,
  UpdateCallGuideMutation,
  UpdateCallGuideMutationVariables,
  UpdateCallGuidesMutation,
  UpdateCallGuidesMutationVariables,
  useUpdateCallGuideMutation,
  useUpdateCallGuidesMutation,
} from "../..";
import {
  removeFromCurrentUserSharedCallGuidesQuery,
  removeFromPositionQuery,
  removeFromSharedCallGuidesQuery,
  updateCurrentUserSharedCallGuidesQuery,
  updatePositionQuery,
  updateSharedCallGuidesQuery,
} from "./utils";

export default function useUpdateCallGuide({
  onCompleted,
  onError,
  positionId,
}: {
  onCompleted?: () => void;
  onError?: (error: ApolloError) => void;
  positionId?: string;
}): [
  MutationTuple<UpdateCallGuideMutation, UpdateCallGuideMutationVariables>[0],
  MutationTuple<UpdateCallGuidesMutation, UpdateCallGuidesMutationVariables>[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [updateCallGuide, { error: singleError, loading: singleLoading }] =
    useUpdateCallGuideMutation({
      onCompleted,
      onError,
      update(cache, { data: updateCallGuideData }) {
        const updatedCallGuide =
          updateCallGuideData?.updateCallGuide?.callGuide;
        if (updatedCallGuide) {
          if (updatedCallGuide.visibility === CallGuideVisibility.Public) {
            updateSharedCallGuidesQuery(cache, updatedCallGuide);
            updateCurrentUserSharedCallGuidesQuery(cache, updatedCallGuide);
          } else {
            removeFromSharedCallGuidesQuery(cache, updatedCallGuide.id);
            removeFromCurrentUserSharedCallGuidesQuery(
              cache,
              updatedCallGuide.id
            );
          }
          // if the related position changed, update the cache
          if (positionId !== updatedCallGuide.position?.id) {
            if (updatedCallGuide.position?.id) {
              updatePositionQuery(
                cache,
                updatedCallGuide,
                updatedCallGuide.position.id
              );
            }
            if (positionId) {
              removeFromPositionQuery(cache, updatedCallGuide.id, positionId);
            }
          }
        }
      },
    });

  const [updateCallGuides, { error: bulkError, loading: bulkLoading }] =
    useUpdateCallGuidesMutation({
      onCompleted,
      onError,
      update(cache, { data: updateCallGuidesData }) {
        const updatedCallGuides =
          updateCallGuidesData?.updateCallGuides?.callGuides;
        if (updatedCallGuides) {
          updatedCallGuides.forEach((updatedCallGuide) => {
            // if the related position changed, update the cache
            if (positionId !== updatedCallGuide?.position?.id) {
              if (updatedCallGuide?.position?.id) {
                updatePositionQuery(
                  cache,
                  updatedCallGuide,
                  updatedCallGuide.position.id
                );
              }
              if (positionId) {
                removeFromPositionQuery(
                  cache,
                  updatedCallGuide?.id,
                  positionId
                );
              }
            }
          });
        }
      },
    });

  return [
    updateCallGuide,
    updateCallGuides,
    {
      loading: singleLoading || bulkLoading,
      error: singleError || bulkError,
    },
  ];
}
