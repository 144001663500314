import { Box, Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

/**
 * A modal-like experience that sits on top of a page but has a full screen layout.
 * Useful for nested workflows that won't trigger full page re-renders when swapping
 * back on slow pages like the interview page.
 */

type FullscreenModalProps = {
  children: React.ReactNode;
};

const largeBreakpointLeftOffset = "56px";
const pageHeaderHeight = "68px";

/**
 * TODO: medium and small breakpoint layouts. This is a WIP.
 */
const FullscreenModal: React.FC<FullscreenModalProps> = ({ children }) => {
  return (
    <Box
      position="fixed"
      top="0"
      left={largeBreakpointLeftOffset}
      width={`calc(100vw - ${largeBreakpointLeftOffset})`}
      height="100vh"
      zIndex="3"
      bg="white"
      px="32px"
    >
      {children}
    </Box>
  );
};

export const FullscreenHeader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Flex height={pageHeaderHeight} flexDir="column" flexShrink={0}>
      {children}
    </Flex>
  );
};

export const FullscreenContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Flex
      overflowY="auto"
      height={`calc(100vh - ${pageHeaderHeight})`}
      flex="1"
      flexDir="row"
    >
      {children}
    </Flex>
  );
};

export const FullscreenColumn: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      pt={8}
      pb={4}
      height="100%"
      overflowY="auto"
      flex="1"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export default FullscreenModal;
