import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, PageTitle } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { DefaultPageLayout } from "../../layouts";
import TemplatesList from "./TemplatesList";

const StyledTab: typeof Tab = (props) => <Tab pb="2.5" mb="0" {...props} />;

const TemplatesPage: React.FC = () => {
  usePageTracker("templates");
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  const navigate = useNavigate();
  const location = useLocation();

  const pickTabIndex = (pathname: string): number => {
    if (pathname.includes("shared")) {
      return 1;
    }
    return 0;
  };

  const [activeTab, setActiveTab] = useState<number>(
    pickTabIndex(location.pathname)
  );

  const handleChangeTab = (index: number): void => {
    if (index === 0) {
      navigate(
        { pathname: "/templates", search: location.search },
        { replace: true }
      );
    } else if (index === 1) {
      navigate(
        { pathname: "/templates/shared", search: location.search },
        { replace: true }
      );
    }
    setActiveTab(index);
  };

  return (
    <DefaultPageLayout maxW="min(100%, 1400px)" p={pagePadding}>
      <Flex alignItems="center" justifyContent="space-between">
        <PageTitle mb="20px" titleText="AI Notes Templates" />
        <Button
          bg="blue.500"
          ml={4}
          size="sm"
          leftIcon={<MdAdd />}
          onClick={() => {
            // TODO: Implement the add template functionality
            navigate("/templates/new");
          }}
        >
          New template
        </Button>
      </Flex>
      <Tabs isLazy index={activeTab} onChange={handleChangeTab}>
        <TabList borderBottom="1px solid" borderColor="gray.200">
          <StyledTab>My Templates</StyledTab>
          <StyledTab>Shared Templates</StyledTab>
        </TabList>

        <TabPanels>
          <TabPanel data-testid="my-call-ai-summary-templates-panel">
            <TemplatesList category="my" active={activeTab === 0} />
          </TabPanel>
          <TabPanel data-testid="shared-call-ai-summary-templates-panel">
            <TemplatesList category="shared" active={activeTab === 1} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </DefaultPageLayout>
  );
};

export default TemplatesPage;
