import { ApolloError } from "@apollo/client";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Alert, Button, Link } from "../../../components";
import DisclaimerInputs, {
  DisclaimerFormData,
} from "../../../components/DisclaimerInputs/DisclaimerInputs";
import {
  CallType,
  Maybe,
  NotetakerRecorderDisclaimerType,
} from "../../graphql";

export interface FormData extends DisclaimerFormData {
  enabled: boolean;
  sendCancelRecordingChatMessage: boolean;
  virtualOnsiteEnabled: boolean;
  displayName?: Maybe<string>;
}

interface ZoomNotetakerSettingsFormProps {
  organizationName: string;
  zoomNotetakerEnabled: boolean;
  sendCancelRecordingChatMessage: boolean;
  zoomNotetakerDisclaimer?: Maybe<string>;
  zoomNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  defaultDisclaimer: string;
  defaultDisplayName?: Maybe<string>;
  virtualOnsiteEnabled: boolean;
  showVirtualOnsiteSetting: boolean;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const ZoomNotetakerSettingsForm: React.FC<ZoomNotetakerSettingsFormProps> = ({
  organizationName,
  zoomNotetakerEnabled,
  sendCancelRecordingChatMessage,
  zoomNotetakerDisclaimer,
  zoomNotetakerDisclaimerType,
  defaultDisclaimer,
  defaultDisplayName,
  virtualOnsiteEnabled,
  showVirtualOnsiteSetting,
  error,
  isLoading,
  onSubmit,
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      enabled: zoomNotetakerEnabled,
      sendCancelRecordingChatMessage,
      disclaimer: zoomNotetakerDisclaimer ?? "",
      disclaimerType: zoomNotetakerDisclaimerType,
      defaultDisclaimer,
      virtualOnsiteEnabled,
      displayName: defaultDisplayName,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        autoComplete="on"
        onSubmit={handleSubmit((formData) => {
          onSubmit(formData);
        })}
      >
        {error?.graphQLErrors?.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Alert mb={8} key={i} status="error" description={message} />
        ))}
        {/** Primary fields */}
        <FormControl id="zoomNotetakerEnabled" mb={8}>
          <Checkbox
            {...register("enabled")}
            defaultChecked={zoomNotetakerEnabled}
          >
            Enabled
          </Checkbox>
          <FormHelperText>
            This turns on the Zoom Notetaker for your organization.
          </FormHelperText>
        </FormControl>

        <FormControl id="sendCancelRecordingChatMessage" mb={8}>
          <Checkbox
            {...register("sendCancelRecordingChatMessage")}
            defaultChecked={sendCancelRecordingChatMessage}
          >
            Send Cancel Recording Link
          </Checkbox>
          <FormHelperText>
            When each participants joins, the Notetaker will send them a message
            which contains a link that when clicked will remove the Notetaker
            and delete the recording.
          </FormHelperText>
        </FormControl>

        <FormControl
          id="displayName"
          mb={8}
          isInvalid={errors.displayName !== undefined}
        >
          <Input
            {...register("displayName")}
            placeholder={`${organizationName} Notetaker`}
            data-testid="display-name"
            maxLength={60}
          />
          <FormHelperText>
            The name that will be displayed for the Zoom Notetaker in meetings.
          </FormHelperText>
          {errors.displayName !== undefined && (
            <FormErrorMessage>{errors.displayName.message}</FormErrorMessage>
          )}
        </FormControl>

        {/** Disclaimer fields */}
        <DisclaimerInputs callType={CallType.ZoomNotetaker} />

        <Box mb={6} hidden={!zoomNotetakerEnabled || !showVirtualOnsiteSetting}>
          <Divider mb={6} />
          <Heading as="h4" fontSize="md" mb={6}>
            Virtual Onsite
          </Heading>

          <FormControl id="virtualOnsiteEnabled">
            <Checkbox
              {...register("virtualOnsiteEnabled")}
              defaultChecked={virtualOnsiteEnabled}
              data-testid="virtual-onsite-enabled"
            >
              Enabled
            </Checkbox>
            <FormHelperText>
              Use interviewer attendance to automatically create separate
              interview recordings of sequential scheduled interviews that share
              a meeting meeting url. This will also provide interviewers more
              control over joining, starting, stopping the different sections of
              onsite via the Interview Assistant.
            </FormHelperText>
            <FormHelperText>
              <Link
                href="http://help.brighthire.ai/en/articles/6809892-virtual-onsites"
                target="_blank"
              >
                {" Learn More"}
              </Link>
            </FormHelperText>
          </FormControl>
        </Box>

        <Button
          type="submit"
          mt={4}
          isLoading={isLoading}
          data-testid="zoom-notetaker-settings-submit"
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};

export default ZoomNotetakerSettingsForm;
