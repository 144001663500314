import { useEffect, useState } from "react";

/**
 * Like `useLocalStorageState`, but never persists the initial value.
 */
function useLazyLocalStorageState<T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const getStoredValue = (): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(getStoredValue);

  useEffect(() => {
    if (storedValue === initialValue) {
      localStorage.removeItem(key);
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify(storedValue));
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [key, storedValue, initialValue]);

  return [storedValue, setStoredValue];
}

export default useLazyLocalStorageState;
