import { Box, BoxProps, Icon } from "@chakra-ui/react";
import React from "react";
import { HiXMark } from "react-icons/hi2";

import { useDismissable } from "../../../../hooks/useDismissable";

type SummaryBannerProps = {
  bannerTypeForDismissing: string;
  bannerKey: string;
  children: React.ReactNode;
} & BoxProps;

const SummaryBanner: React.FC<SummaryBannerProps> = ({
  bannerKey,
  bannerTypeForDismissing,
  children,
  ...boxProps
}) => {
  const { dismissed, dismiss } = useDismissable(
    `${bannerTypeForDismissing}:${bannerKey}`
  );
  if (dismissed) {
    return null;
  }

  return (
    <Box
      bg="yellow.100"
      color="yellow.700"
      py={2}
      px={3}
      pr={7}
      mx={6}
      mb={4}
      borderRadius="base"
      fontSize="sm"
      position="relative"
      {...boxProps}
    >
      {children}
      <Icon
        as={HiXMark}
        color="yellow.700"
        h="16px"
        w="16px"
        position="absolute"
        right={2}
        top={2}
        cursor="pointer"
        onClick={dismiss}
      />
    </Box>
  );
};

export default SummaryBanner;
