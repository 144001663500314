import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Controller, UseFormRegister } from "react-hook-form";
import ReactSelect from "react-select";

import { Button, ConfirmModal } from "../../../../components";
import { TimezoneSelect } from "../../../components";
import { Ats, useOrgUsersOnlyQuery } from "../../../graphql";
import { DataRetentionInputsProps, FormValues } from "./types";
import { useDataRetentionSettingsForm } from "./useDataRetentionSettingsForm";

const ATS_LIST = [Ats.Greenhouse, Ats.Lever, Ats.Smartrecruiters, Ats.Ashby];

const DataRetentionInputsPage: React.FC<DataRetentionInputsProps> = ({
  organization,
}) => {
  const { data: orgUsers, loading: orgUsersLoading } = useOrgUsersOnlyQuery();

  const {
    register,
    onSubmit,
    submitLoading,
    control,
    resetField,
    watch,
    confirmationModal,
  } = useDataRetentionSettingsForm({ organization });

  const automaticDeletionSelected = watch("enableAutomaticRetentionDeletion");

  const userOptions = useMemo(() => {
    const filtered = (orgUsers?.currentUser?.organization?.users || []).filter(
      (u) => u.userRole?.name === "SITE_ADMIN"
    );
    filtered.sort((ua, ub) => {
      if (ua.fullName < ub.fullName) return -1;
      if (ua.fullName > ub.fullName) return 1;
      return 0;
    });
    return filtered.map((user) => {
      return {
        label: `${user.fullName} - ${user.email}`,
        value: user.id,
      };
    });
  }, [orgUsers]);
  // This sets selectedUsers after initial data is loaded
  useEffect(() => {
    const retentionEmailList = organization?.organizationRetentionUser || [];
    const selectedUsers = userOptions.filter((option) => {
      return retentionEmailList.some((x) => x.userId === option.value);
    });
    resetField("retentionEmailList", { defaultValue: selectedUsers });
  }, [userOptions, organization]);

  const times = useMemo((): string[] => {
    const dt = new Date(1970, 0, 1);
    const step = 15;
    const rc = [];
    while (dt.getDate() === 1) {
      rc.push(
        dt.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
      );
      dt.setMinutes(dt.getMinutes() + step);
    }
    return rc;
  }, []);

  const loading = orgUsersLoading || submitLoading;

  return (
    <form onSubmit={onSubmit}>
      <Heading as="h3" size="sm" mt={8} mb={4}>
        Triggers
      </Heading>
      <Text>
        Choose when to mark candidates and interviews as ready for deletion
      </Text>
      {organization.ats && ATS_LIST.includes(organization.ats) && (
        <CheckboxField
          name="enableRetentionSinceCandidateHired"
          numberField="retentionDaysSinceCandidateHired"
          register={register}
        >
          If the candidate was marked &quot;hired&quot; in the ATS
        </CheckboxField>
      )}
      {organization.ats && ATS_LIST.includes(organization.ats) && (
        <CheckboxField
          name="enableRetentionSinceCandidateRejected"
          numberField="retentionDaysSinceCandidateRejected"
          register={register}
        >
          If the candidate was marked &quot;rejected&quot; in the ATS
        </CheckboxField>
      )}
      <CheckboxField
        name="enableRetentionSinceLastInterview"
        numberField="retentionDaysSinceLastInterview"
        register={register}
      >
        If the candidate&apos;s last interview was
      </CheckboxField>
      {organization.ats === Ats.Greenhouse && (
        <CheckboxField name="enableRedactionForGreenhouse" register={register}>
          If the candidate&apos;s data is anonymized in Greenhouse.
        </CheckboxField>
      )}
      {organization.ats === Ats.Lever && (
        <CheckboxField name="enableRedactionForLever" register={register}>
          If the candidate&apos;s data is anonymized in Lever.
        </CheckboxField>
      )}
      {organization.ats === Ats.Smartrecruiters && (
        <CheckboxField
          name="enableRedactionForSmartrecruiters"
          register={register}
        >
          If the candidate&apos;s data is anonymized in SmartRecruiters.
        </CheckboxField>
      )}
      {organization.ats === Ats.Ashby && (
        <CheckboxField name="enableRedactionForAshby" register={register}>
          If the candidate&apos;s data is anonymized in Ashby.
        </CheckboxField>
      )}
      <CheckboxField
        name="enableRetentionSinceInterview"
        numberField="retentionDaysSinceInterview"
        register={register}
      >
        If the interview took place
      </CheckboxField>
      <Heading as="h3" size="sm" mt={8} mb={4}>
        Training settings
      </Heading>
      <CheckboxField name="preventRedactionForTraining" register={register}>
        Prevent deletion of candidates and interviews that are in training
        programs.
      </CheckboxField>
      <Heading as="h3" size="sm" mt={8} mb={4}>
        Automatic deletion
      </Heading>
      <CheckboxField
        name="enableAutomaticRetentionDeletion"
        register={register}
      >
        Automatically delete candidates and interviews matching the rules above
      </CheckboxField>
      {automaticDeletionSelected && (
        <Alert status="warning" mt="4" maxW={620} borderRadius="md">
          <AlertIcon alignSelf="start" />
          <AlertDescription>
            This will result in candidates and interviews being{" "}
            <strong>automatically deleted</strong> from BrightHire.
            <br />
            <Text fontSize="sm" mt="2">
              If you prefer manual deletion, you may disable this option and
              configure notifications for items that are ready to be deleted.
            </Text>
          </AlertDescription>
        </Alert>
      )}
      <Box hidden={automaticDeletionSelected}>
        <Heading as="h3" size="sm" mt={8} mb={4}>
          Notification Settings
        </Heading>
        <Text w="100%">
          Send the selected users below a notification email if there are
          candidates or interviews to delete.
        </Text>
        <FormControl width="300px" mt={2}>
          <FormLabel>Send email on:</FormLabel>
          <Stack spacing={[1, 2]} direction={["column", "row"]}>
            <Controller
              control={control}
              name="retentionNotificationDays"
              render={({ field: { value, onChange } }) => (
                <>
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                    (day, idx) => (
                      <Checkbox
                        key={day}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChange(value.concat(e.target.value));
                          } else {
                            onChange(value.filter((v) => v !== e.target.value));
                          }
                        }}
                        isChecked={value.indexOf(`${idx}`) >= 0}
                        value={`${idx}`}
                      >
                        {day}
                      </Checkbox>
                    )
                  )}
                </>
              )}
            />
          </Stack>
        </FormControl>
        <FormControl width="450px" mt={2}>
          <FormLabel>Recipients:</FormLabel>
          <Controller
            control={control}
            name="retentionEmailList"
            render={({ field }) => (
              <ReactSelect
                data-testid="exclusion-select"
                isClearable
                isMulti
                isLoading={orgUsersLoading}
                placeholder="Add users to send email"
                options={userOptions}
                {...field}
              />
            )}
          />
        </FormControl>
        <FormControl width="300px" mt={2}>
          <FormLabel>Time:</FormLabel>
          <Controller
            control={control}
            name="retentionEmailSendTime"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                name="retentionEmailSendTime"
                ref={ref}
                value={value}
                onChange={(v) => {
                  onChange(v.target.value);
                }}
              >
                {times.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl width="300px" mt={2}>
          <FormLabel>Timezone:</FormLabel>
          <Controller
            control={control}
            defaultValue={organization.retentionEmailSendTimezone}
            name="retentionEmailSendTimezone"
            render={({ field: { onChange, value } }) => (
              <TimezoneSelect timezone={value} onChange={onChange} />
            )}
          />
        </FormControl>
      </Box>
      <Button mt={8} type="submit" variant="solid" isLoading={loading}>
        Save
      </Button>
      <ConfirmModal
        isDangerous
        size="lg"
        modalTitleText="Auto deletion warning"
        modalCancelText="Go back"
        modalSubmitText="Continue"
        {...confirmationModal}
      />
    </form>
  );
};

export default DataRetentionInputsPage;

const CheckboxField: React.FC<{
  name: keyof FormValues;
  register: UseFormRegister<FormValues>;
  numberField?: keyof FormValues;
  children: React.ReactNode;
}> = ({ name, numberField, register, children }) => {
  return (
    <FormControl mt={2}>
      <HStack>
        <Checkbox {...register(name)} />
        <Text>{children}</Text>
        {numberField && (
          <>
            <NumberInput
              max={3000}
              min={14}
              defaultValue={365}
              width="100px"
              size="sm"
            >
              <NumberInputField
                {...register(numberField, { valueAsNumber: true })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text ml={1}>days ago.</Text>
          </>
        )}
      </HStack>
    </FormControl>
  );
};
