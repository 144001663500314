import { Box, Divider, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineChatAlt2,
  HiOutlineClipboardList,
  HiOutlineCog,
} from "react-icons/hi";
import { HiOutlineUsers } from "react-icons/hi2";

import PlugConnected from "../../../components/Icons/PlugConnected";
import { useOrgAccessMap } from "../../../hooks/useOrgFeatureAccess";
import { CurrentUserFragment, FeatureName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import SettingsNavAccordion from "./SettingsNavAccordion";
import { SettingsNavLink } from "./SettingsNavLink";
import { SettingsTitle } from "./SettingsTitle";

interface SettingsNavProps {
  currentUser: CurrentUserFragment;
}

const SettingsNav: React.FC<SettingsNavProps> = ({ currentUser }) => {
  const draftScorecardFeatureVisible = useFeatureFlag("greenhouse:v3-api");
  const accessMap = useOrgAccessMap(currentUser);

  const interviewSettingsSubItems = [
    { displayName: "Calendar", to: "/settings/calendar" },
    {
      displayName: "Text message templates",
      to: "/settings/templates",
    },
  ];
  if (
    currentUser.organization.allowsGreenhouseScorecardAutoDraft &&
    draftScorecardFeatureVisible
  ) {
    interviewSettingsSubItems.push({
      displayName: "Scorecards",
      to: "/settings/user/scorecard",
    });
  }
  if (currentUser.organization.allowUserLevelImportRules) {
    interviewSettingsSubItems.push({
      displayName: "Automatic Recording Options",
      to: "/settings/my-interview-join-options",
    });
  }
  if (
    currentUser.organization.isZoomAuthorized ||
    currentUser.isZoomAppInstalled
  ) {
    interviewSettingsSubItems.push({
      displayName: "Zoom app",
      to: "/settings/user/zoom",
    });
  }

  const complianceSubItems = [];
  if (
    accessMap?.get(FeatureName.ComplianceCheck)?.isVisible ||
    accessMap?.get(FeatureName.CandidateOptOut)?.isVisible ||
    accessMap?.get(FeatureName.InterviewerOptOut)?.isVisible ||
    accessMap?.get(FeatureName.RecordingDisclaimer)?.isVisible
  ) {
    complianceSubItems.push({
      displayName: "Settings",
      to: "/settings/compliance-check",
    });
  }
  if (accessMap?.get(FeatureName.DataRetention)?.isVisible) {
    complianceSubItems.push({
      displayName: "Data retention",
      to: "/settings/data-retention",
    });
  }
  if (accessMap?.get(FeatureName.CandidateOptIn)?.isVisible) {
    complianceSubItems.push({
      displayName: "Opt-in landing page",
      to: "/settings/opt-in",
    });
  }

  const videoConferenceSubItems = [];
  if (accessMap?.get(FeatureName.Zoom)?.isVisible) {
    videoConferenceSubItems.push({
      displayName: "Zoom",
      to: "/settings/zoom-notetaker",
    });
  }
  if (accessMap?.get(FeatureName.GoogleMeet)?.isVisible) {
    videoConferenceSubItems.push({
      displayName: "Google Meet",
      to: "/settings/google-meet",
    });
  }
  if (accessMap?.get(FeatureName.MsTeams)?.isVisible) {
    videoConferenceSubItems.push({
      displayName: "Microsoft Teams",
      to: "/settings/msft-teams",
    });
  }
  if (accessMap?.get(FeatureName.ZoomNative)?.isVisible) {
    videoConferenceSubItems.push({
      displayName: "Zoom (legacy integration)",
      to: "/settings/zoom",
    });
  }

  const atsSubItems = [];
  if (accessMap?.get(FeatureName.Greenhouse)?.isVisible) {
    atsSubItems.push({
      displayName: "Greenhouse",
      to: "/settings/greenhouse",
    });
  }
  if (accessMap?.get(FeatureName.Lever)?.isVisible) {
    atsSubItems.push({
      displayName: "Lever",
      to: "/settings/lever",
    });
  }
  if (accessMap?.get(FeatureName.Smartrecruiters)?.isVisible) {
    atsSubItems.push({
      displayName: "SmartRecruiters",
      to: "/settings/smartrecruiters",
    });
  }
  if (accessMap?.get(FeatureName.Icims)?.isVisible) {
    atsSubItems.push({
      displayName: "iCIMS",
      to: "/settings/icims",
    });
  }
  if (accessMap?.get(FeatureName.Bullhorn)?.isVisible) {
    atsSubItems.push({
      displayName: "Bullhorn",
      to: "/settings/bullhorn",
    });
  }
  if (accessMap?.get(FeatureName.Merge)?.isVisible) {
    atsSubItems.push({
      displayName: "Other ATS integrations",
      to: "/settings/other-ats",
    });
  }

  return (
    <Box gridRow="1 / 3" borderRight="1px" borderColor="gray.200">
      <Box pb="3" data-tour-id="settings-section-my-settings">
        <SettingsTitle title="MY SETTINGS" icon={<Icon as={HiOutlineCog} />} />
        <Flex flexDirection="column">
          <SettingsNavLink end to="/settings/user">
            Profile
          </SettingsNavLink>
          <SettingsNavLink to="/settings/user/notifications">
            Notification preferences
          </SettingsNavLink>
          <SettingsNavLink to="/settings/user/browserExtension">
            Browser Extension
          </SettingsNavLink>
          <SettingsNavAccordion
            heading="Interview settings"
            subItems={interviewSettingsSubItems}
          />
        </Flex>
      </Box>
      {currentUser.userRole?.canManageOrganizationSettings && (
        <>
          <Divider color="gray.200" />
          <Box py="3" data-tour-id="settings-section-users">
            <SettingsTitle title="USERS" icon={<Icon as={HiOutlineUsers} />} />
            <Flex flexDirection="column">
              <SettingsNavLink
                to="/settings/users/list"
                data-tour-id="settings-users"
                matchPaths={["/settings/users/info/:id"]}
              >
                Users list
              </SettingsNavLink>
              <SettingsNavLink
                to="/settings/users/hiring"
                data-tour-id="settings-users"
              >
                Hiring teams
              </SettingsNavLink>
              <SettingsNavLink
                to="/settings/users/roles"
                data-tour-id="settings-users"
              >
                Roles & policies
              </SettingsNavLink>
              <SettingsNavLink
                to="/settings/users/excluded"
                data-tour-id="settings-users"
              >
                Excluded users
              </SettingsNavLink>
            </Flex>
          </Box>
        </>
      )}
      {currentUser?.organization?.planEnabled &&
        currentUser.planUserRole?.canManageOrganizationSettings && (
          <>
            <Divider color="gray.200" />
            <Box py="3" data-tour-id="settings-section-plan-settings">
              <SettingsTitle
                title="PLAN SETTINGS"
                icon={<Icon as={HiOutlineClipboardList} />}
              />
              <Flex flexDirection="column">
                <SettingsNavLink to="/settings/interview-plans">
                  Interview plans
                </SettingsNavLink>
                <SettingsNavLink to="/settings/job-descriptions">
                  Job descriptions
                </SettingsNavLink>
              </Flex>
            </Box>
          </>
        )}
      {currentUser.userRole?.canManageOrganizationSettings && (
        <>
          <Divider color="gray.200" />
          <Box py="3" data-tour-id="settings-section-interview-settings">
            <SettingsTitle
              title="INTERVIEW SETTINGS"
              icon={<Icon as={HiOutlineChatAlt2} />}
            />
            <Flex flexDirection="column">
              <SettingsNavLink to="/settings/organization">
                Sign up & sign in
              </SettingsNavLink>
              <SettingsNavLink to="/settings/shared-calendars">
                Shared calendars
              </SettingsNavLink>
              {accessMap?.get(FeatureName.JoinByPosition)?.isVisible && (
                <SettingsNavLink to="/settings/auto-join-by-position">
                  Auto join by position
                </SettingsNavLink>
              )}
              <SettingsNavLink to="/settings/ai">AI</SettingsNavLink>
              {complianceSubItems.length > 0 && (
                <SettingsNavAccordion
                  heading="Compliance"
                  subItems={complianceSubItems}
                />
              )}
              {accessMap?.get(FeatureName.Scoring)?.isVisible && (
                <SettingsNavLink
                  to="/settings/scoring"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Scoring
                </SettingsNavLink>
              )}
              <SettingsNavLink to="/settings/exclude-stage">
                Exclude stage
              </SettingsNavLink>
              <SettingsNavLink to="/settings/advanced">
                Advanced configurations
              </SettingsNavLink>
            </Flex>
          </Box>
          <Divider color="gray.200" />
          <Box py="3" data-tour-id="settings-section-integrations">
            <SettingsTitle title="INTEGRATIONS" icon={<PlugConnected />} />
            <Flex flexDirection="column">
              {videoConferenceSubItems.length > 0 && (
                <SettingsNavAccordion
                  heading="Video conferencing"
                  subItems={videoConferenceSubItems}
                />
              )}
              {atsSubItems.length > 0 && (
                <SettingsNavAccordion heading="ATS" subItems={atsSubItems} />
              )}
              {accessMap?.get(FeatureName.Sense)?.isVisible && (
                <SettingsNavAccordion
                  heading="CRM"
                  subItems={[{ displayName: "Sense", to: "/settings/sense" }]}
                />
              )}
              {accessMap?.get(FeatureName.OktaAuth)?.isVisible && (
                <SettingsNavLink to="/settings/sso">SSO</SettingsNavLink>
              )}
              <SettingsNavLink to="/settings/slack">Slack</SettingsNavLink>
              {accessMap?.get(FeatureName.RestApi)?.isVisible && (
                <SettingsNavLink to="/settings/rest">
                  BrightHire API
                </SettingsNavLink>
              )}
            </Flex>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SettingsNav;
